import { CallStatsFilter, callStatsPeriods, getCallStatsByInterval } from "@/google/callStats"
import { setCallStatsByInterval } from "@/store/callStatsSlice"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

let prevFilter: CallStatsFilter | null = null

export const useCallStatsByInterval = (filter: CallStatsFilter) => {
    const user = useSelector((state: RootState) => state.user)
    const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
    const callStats = useSelector((state: RootState) => state.callStats.stats)
    const dispatch = useDispatch<AppDispatch>()
    const [fetching, setFetching] = useState(false)

    useEffect(() => {
        const fetch = async () => {
            if(!isSignedIn) {
                prevFilter = null
                return
            }
            if(!filter || !filter.companyId || !callStatsPeriods.includes(filter.period))
                return
            if(prevFilter && Object.keys(filter).reduce((prev, curr) => prev && filter[curr] === prevFilter[curr], true))
                return
            console.log("fetch call stats by interval")
            
            try {
                setFetching(true)
                const log = await getCallStatsByInterval(filter)
                if(!log) {
                    return
                }
                dispatch(setCallStatsByInterval(log))
                prevFilter = filter
            } catch(e) {
                console.error(e)
            } finally {
                setFetching(false)
            }
        }
        fetch()
    }, [dispatch, user.companyId, isSignedIn, filter])

    return [callStats, fetching] as const
}