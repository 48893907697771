import { CallListItem, CallListMetadata } from "@/models/CallList";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocRef, getRandomDocRef, getSnapshot, getSnapshots, runTransaction, deleteDoc, setDoc, updateDocument, addDocument, addDocumentWithDocId, getCollectionRef, getRandomDocId } from "@/google/firestore";
import formatTimestamp from "@/utils/formatTimestamp";
import { RootState } from "./store";
import { getDocs, query, Timestamp, where } from "firebase/firestore";
import {getDocData} from "@/google/firestore"
import { CallHistoryRecord, CallResult } from "@/models/CallHistory";
import { downloadFromStorage } from "@/google/storage";
import { Call, Device } from "@twilio/voice-sdk"
import CallProcess from "@/models/CallProcess";
import { Script, ScriptLines } from "@/models/Script";

type CallState = {
    customCallResultLabels: { [key in CallResult]?: string } | null
    callProcesses: {
        [docId: string]: CallProcess
    },
    callListMetadata: {
        [docId: string]: CallListMetadata
    }
    callListItems: {
        [callListId: string]: {
            [docId: string]: CallListItem
        }
    }
    callListItemsUploadProgress: number,
    callHistoryCurrentPage: number
    callHistoryPageIsFetched: boolean[]
    filteredCallHistoryNum: number
    callHistory: {
        [docId: string]: CallHistoryRecord
    }
    filteredCallHistory: {
        [docId: string]: CallHistoryRecord
    }
    transcripts: {
        [path: string]: string
    },
    scripts: {
        [docId: string]: Script
    }
    scriptLines: {
        [docId: string]: ScriptLines
    }
    manualRedialCalls: {
        [callSid: string]: CallHistoryRecord
    },
    aiRedialCalls: {
        [callSid: string]: CallHistoryRecord
    }

    // 通話
    callDevice: Device | null
    call: Call | null
    assignedPid: number
}

const initialState: CallState = {
    customCallResultLabels: null,
    callProcesses: {},
    callListMetadata: {},
    callListItems: {},
    callListItemsUploadProgress: 0,
    callHistory: {},
    filteredCallHistory: {},
    filteredCallHistoryNum: 0,
    callHistoryCurrentPage: 1,
    callHistoryPageIsFetched: [],
    transcripts: {},
    scripts: {},
    scriptLines: {},
    manualRedialCalls: {},
    aiRedialCalls: {},

    callDevice: null,
    call: null,
    assignedPid: -1
}

export const getCallListMetadata = createAsyncThunk<
    CallListMetadata[],
    string,
    { state: RootState }
>("call/getCallListMetadata", async (companyId, { getState }) => {
    const state = getState().call
    if(Object.values(state.callListMetadata).length)
        return
    const snapshots = await getSnapshots(`/companies/${companyId}/callListMetadata`)
    const metadata = snapshots
        .map(snapshot => snapshot.data() as CallListMetadata)
    console.debug("call list metadata fetched")
    return metadata
})

export const deleteCallList = createAsyncThunk<
    string,
    { companyId: string, id: string }
>("call/deleteCallList", async ({ companyId, id }) => {
    await runTransaction(async (transaction) => {
        console.log(`/companies/${companyId}/callListMetadata/${id}`)
        transaction.delete(getDocRef(`/companies/${companyId}/callListMetadata/${id}`))
        const snapshot = await getDocs(query(getCollectionRef(`/companies/${companyId}/callListItems_v2`), where("callListId", "==", id)))
        console.log(snapshot.docs.length)
        await Promise.all(snapshot.docs.map(doc => transaction.delete(doc.ref)))
    })
    console.debug("call list deleted")
    return id
})

export const updateCallList = createAsyncThunk<
    { metadata: CallListMetadata, items?: CallListItem[], id: string },
    { companyId: string, id: string, items?: CallListItem[], metadata: CallListMetadata }
>("call/updateCallList", async ({ companyId, id, items, metadata }, { rejectWithValue }) => {
    if(!metadata) 
        rejectWithValue({ message: `metadata doesn't exist in local (id: ${id})` })

    await runTransaction(async (transaction) => {
        transaction.set(getDocRef(`/companies/${companyId}/callListMetadata/${id}`), metadata)
        if(items) {
            await Promise.all(items.map(async (item, index) => {
                item.callListId = id
                return transaction.set(getDocRef(`/companies/${companyId}/callListItems_v2/${item.id}`), item)
            }))
        }
    })
    console.debug("call list updated")
    return { metadata, items, id }
})

export const deleteCallListItems = createAsyncThunk<
    { callListId: string, callListItemIds: string[] },
    { companyId: string, callListId: string, indexes: number[] }
>("call/deleteCallListItem", async ({ companyId, callListId, indexes }) => {
    const q = query(
        getCollectionRef(`/companies/${companyId}/callListItems_v2`),
        where("callListId", "==", callListId),
        where("index", "in", indexes)
    )
    const snapshots = await getSnapshots(q)
    const results = snapshots.map(snapshot => {
        return deleteDoc(`/companies/${companyId}/callListItems_v2`, snapshot.ref.id)
    })
    await Promise.all(results)
    return {
        callListId,
        callListItemIds: snapshots.map(snapshot => snapshot.id)
    }
})

// export const getTranscript = createAsyncThunk<
//     { id: string, transcript: string } | void,
//     { companyId: string, callHistoryId: string },
//     { state: RootState }
// >("call/getTranscript", async ({ companyId, callHistoryId }, { getState }) => {
//     const call = getState().call
//     if(call.transcripts[callHistoryId])
//         return
    
//     const transcript = await getDocData(`/companies/${companyId}/callHistory/${callHistoryId}/conversations/${callHistoryId}`); //downloadFromStorage({ path: `company/${companyId}/log/transcript/${callHistoryId}.txt`, responseType: "text" }) as string

//     if(transcript){
//         const transcriptText:string = transcript.conversationHistory;
//         console.log("これがスクリプト", transcriptText)
//         return { id: callHistoryId, transcript: transcriptText }
//     }
// })

export const getTranscript = createAsyncThunk<
    { id: string, transcript: string } | void,
    { companyId: string, callHistoryId: string },
    { state: RootState }
>("call/getTranscript", async ({ companyId, callHistoryId }, { getState }) => {
    const call = getState().call
    if(call.transcripts[callHistoryId])
        return
    
    const transcript = await downloadFromStorage({ path: `company/${companyId}/log/transcript/${callHistoryId}.txt`, responseType: "text" }) as string

    return { id: callHistoryId, transcript }
})

export const getCallHistoryById = createAsyncThunk<
CallHistoryRecord,
{ companyId: string, callHistoryId: string },
{ state: RootState }
>("call/getCallHistoryById", async ({ companyId, callHistoryId }, { getState }) => {
    const snapshot = await getSnapshot(`/companies/${companyId}/history/${callHistoryId}`)
    return snapshot.data() as CallHistoryRecord
})

export const addCallHistory = createAsyncThunk<
    { docId: string, record: CallHistoryRecord },
    { companyId: string, record: CallHistoryRecord }
>("call/addCallHistory", async ({ companyId, record }) => {
    await setDoc(`/companies/${companyId}/callHistory`, record.id, record)

    return { docId: record.id, record }
})

export const getCallProcesses = createAsyncThunk<
    { [pid: number]: CallProcess },
    { companyId: string }
>("call/getCallProcesses", async ({ companyId }) => {
    const snapshots = await getSnapshots(`/companies/${companyId}/callProcesses`)
    const processes = snapshots.map(snapshot => ([snapshot.id, snapshot.data()]))   // IDがPIDになっている
    console.debug(`fetched ${processes.length} processed`)
    return Object.fromEntries(processes)
})

export const deleteCallProcess = createAsyncThunk<
    string,
    { companyId: string, docId: string }
>("call/deleteCallProcess", async ({ companyId, docId }) => {
    await deleteDoc(`/companies/${companyId}/callProcesses`, String(docId))
    return docId
})

export const getScripts = createAsyncThunk<
    { [scriptId: string]: Script },
    { companyId: string }
>("call/getScripts", async ({ companyId }) => {
    const snapshots = await getSnapshots(`/companies/${companyId}/scripts`)
    const scripts = snapshots.map(snapshot => [snapshot.id, snapshot.data()])
    console.debug(`fetched ${scripts.length} scripts`)
    return Object.fromEntries(scripts)
})

export const getScriptLines = createAsyncThunk<
    { scriptLines: ScriptLines, scriptId: string },
    { companyId: string, scriptId: string }
>("call/getScriptLines", async ({ companyId, scriptId }) => {
    const snapshot = await getSnapshot(`/companies/${companyId}/scriptLines/${scriptId}`)
    console.debug("fetched script lines")
    return {
        scriptLines: snapshot.data() as ScriptLines,
        scriptId
    }
})

export const updateScript = createAsyncThunk<
    { scriptId: string, script: Partial<Script> },
    { companyId: string, scriptId: string, script: Partial<Script> }
>("call/updateScript", async ({ companyId, scriptId, script }) => {
    await updateDocument(`/companies/${companyId}/scripts/${scriptId}`, script)
    return { scriptId, script }
})

const callSlice = createSlice({
    name: "call",
    initialState,
    reducers: {
        setCallDevice: (state, action: PayloadAction<Device>) => {
            state.callDevice = action.payload
        },
        setCall: (state, action: PayloadAction<Call>) => {
            state.call = action.payload
        },
        setAssignedPid: (state, action: PayloadAction<number>) => {
            state.assignedPid = action.payload
        },
        initCallSlice: (state, action: PayloadAction) => {
            Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
                state[key] = value
            })
            console.debug("call slice initialized")
        },
        updateCallProcesses: (state, action: PayloadAction<[string, CallProcess]>) => {
            state.callProcesses = {
                ...state.callProcesses,
                [action.payload[0]]: action.payload[1]
            }
            console.debug(`call process (pid: ${action.payload[0]}) refetched`)
        },
        deleteLocalCallProcess: (state, action: PayloadAction<string>) => {
            // immutableに更新しないと変更が反映されないため、直接のdeleteは避ける
            const newCallProcesses = { ...state.callProcesses }
            delete newCallProcesses[action.payload]
            state.callProcesses = newCallProcesses
            console.debug(`call process (pid: ${action.payload}) deleted`)
        },
        updateLocalCallHistory: (state, action: PayloadAction<{ [id: string]: CallHistoryRecord }>) => {
            console.log(state.callHistory, action.payload)
            state.callHistory = {
                ...state.callHistory,
                ...action.payload
            }
            state.filteredCallHistory = {
                ...state.filteredCallHistory,
                ...action.payload
            }
        },
        initCallHistory: (state) => {
            state.callHistory = {}
            state.filteredCallHistory = {}
        },
        setFilteredCallHistoryNum: (state, action: PayloadAction<number>) => {
            state.filteredCallHistoryNum = action.payload
        },
        setCallHistoryCurrentPage: (state, action: PayloadAction<number>) => {
            state.callHistoryCurrentPage = action.payload
        },
        setCallHistoryPageIsFetched: (state, action: PayloadAction<boolean[]>) => {
            state.callHistoryPageIsFetched = action.payload
        },
        updateLocalCallListMetadata: (state, action: PayloadAction<CallListMetadata[]>) => {
            action.payload.forEach(metadata => {
                state.callListMetadata[metadata.id] = metadata
            })
        },
        updateLocalCallListItems:(state, action: PayloadAction<CallListItem[]>) => {
            action.payload.forEach(item => {
                if(!state.callListItems[item.callListId])
                    state.callListItems[item.callListId] = {}
                state.callListItems[item.callListId][item.id] = item
            })
        },
        updateCustomCallResultLabels: (state, action: PayloadAction<{ [key in CallResult]?: string }>) => {
            state.customCallResultLabels = action.payload
        },
        updateAiRedialCalls: (state, action: PayloadAction<CallHistoryRecord[]>) => {
            action.payload.forEach(record => {
                state.aiRedialCalls[record.id] = record
            })
        },
        updateManualRedialCalls: (state, action: PayloadAction<CallHistoryRecord[]>) => {
            action.payload.forEach(record => {
                state.manualRedialCalls[record.id] = record
            })
        },
        deleteManualRedialCalls: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach(id => {
                delete state.manualRedialCalls[id]
            })
        }
    },
    extraReducers(builder) {
        builder.addCase(getCallListMetadata.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getCallListMetadata.fulfilled, (state, action) => {
            state.callListMetadata = Object.fromEntries(action.payload.map(data => ([data.id, data])))
            state.callListItemsUploadProgress = 0
        })

        builder.addCase(updateCallList.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(updateCallList.fulfilled, (state, action) => {
            state.callListMetadata[action.payload.id] = action.payload.metadata
            if(action.payload.items) {
                action.payload.items.forEach(item => {
                    state.callListItems[action.payload.id][item.id] = item
                })
            }
        })

        builder.addCase(deleteCallListItems.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(deleteCallListItems.fulfilled, (state, action) => {
            const callListId = action.payload.callListId
            action.payload.callListItemIds.forEach(id => {
                delete state.callListItems[callListId][id]
            })
        })

        builder.addCase(deleteCallList.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(deleteCallList.fulfilled, (state, action) => {
            const id = action.payload
            delete state.callListMetadata[id]
            delete state.callListItems[id]
        })

        builder.addCase(getTranscript.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getTranscript.fulfilled, (state, action) => {
            if(!action.payload)
                return
            state.transcripts[action.payload.id] = action.payload.transcript
        })

        builder.addCase(getCallHistoryById.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getCallHistoryById.fulfilled, (state, action) => {
            state.callHistory[action.payload.id] = action.payload
        })

        builder.addCase(addCallHistory.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(addCallHistory.fulfilled, (state, action) => {
            state.callHistory[action.payload.docId] = action.payload.record
        })

        builder.addCase(getCallProcesses.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getCallProcesses.fulfilled, (state, action) => {
            state.callProcesses = action.payload
        })

        builder.addCase(deleteCallProcess.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(deleteCallProcess.fulfilled, (state, action) => {
            delete state.callProcesses[action.payload]
        })

        builder.addCase(getScripts.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getScripts.fulfilled, (state, action) => {
            state.scripts = action.payload
        })

        builder.addCase(getScriptLines.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getScriptLines.fulfilled, (state, action) => {
            state.scriptLines[action.payload.scriptId] = action.payload.scriptLines
        })

        builder.addCase(updateScript.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(updateScript.fulfilled, (state, action) => {
            state.scripts[action.payload.scriptId] = {
                ...state.scripts[action.payload.scriptId],
                ...action.payload.script
            }
        })
    },
});

export const {
    setCallDevice,
    setCall,
    setAssignedPid,
    updateCallProcesses,
    deleteLocalCallProcess,
    initCallSlice,
    updateLocalCallHistory,
    initCallHistory,
    setFilteredCallHistoryNum,
    setCallHistoryCurrentPage,
    setCallHistoryPageIsFetched,
    updateLocalCallListItems,
    updateLocalCallListMetadata,
    updateCustomCallResultLabels,
    updateAiRedialCalls,
    updateManualRedialCalls,
    deleteManualRedialCalls
} = callSlice.actions
export default callSlice.reducer