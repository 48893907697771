import { Timestamp } from "firebase/firestore";

export enum PROCESS_STATUS {
  Initializing = "INITIALIZING", //初期化中
  Running = "RUNNING", //実行中
  Aborting = "ABORTING", //停止処理中
  Completed = "COMPLETED", //完了
  Aborted = "ABORTED", //停止
}

type CallProcess = {
  name: string;
  uids: string[];
  callListId: string;
  callAiNum: number;
  callCount: number;
  remainingCallCount: number;
  answerCount: number;
  appointmentCount: number;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  nextCallListId: string;
  aiIsCalling: boolean;
  status: PROCESS_STATUS;
};

export default CallProcess;
