import { getDocData } from "@/google/firestore"
import { CallResult, callResultLabels } from "@/models/CallHistory"
import { updateCustomCallResultLabels } from "@/store/callSlice"
import { AppDispatch, RootState } from "@/store/store"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

let lastFetchedCompanyId = ""

export const useCallResultLabels = (): { [key in CallResult]?: string } => {
    const companyId = useSelector((state: RootState) => state.user.companyId)
    const customCallResultLabels = useSelector((state: RootState) => state.call.customCallResultLabels)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if(!companyId || customCallResultLabels || lastFetchedCompanyId === companyId)
            return
        
        lastFetchedCompanyId = companyId
        const fetch = async () => {
            try {
                const labels = await getDocData(`companies/${companyId}/customCallResultLabels/0`) as { [key in CallResult]?: string }
                if(labels) {
                    dispatch(updateCustomCallResultLabels(labels))
                }
            } catch (e) {
                console.error(e)
            }
        }
        fetch()
    }, [companyId])

    return {
        ...callResultLabels,
        ...(customCallResultLabels||{})
    }
}