import { CallHistoryRecord as CallHistoryRecord, CallResult } from "@/models/CallHistory"
import { and, collection, getDocs, or, query, where } from "firebase/firestore";
import { db } from "@/google/firestore";

// callHistoryのprevCallSidを辿れる回数を再コール回数として返す
const countRedialAttempts = async (companyId: String, record: CallHistoryRecord) => {
    if(!record.prevCallSid)
        return 0
    let redialAttempts = 0
    let currentRecord = record
    while(currentRecord.prevCallSid) {
        if(!currentRecord.prevCallSid)
            break
        redialAttempts++
        const doc = await getDocs(query(collection(db, `companies/${companyId}/callHistory`), where("id", "==", currentRecord.prevCallSid)))
        if(doc.empty)
            break
        currentRecord = doc.docs[0].data() as CallHistoryRecord
    }
    console.log("redial attempts: " + redialAttempts)
    return redialAttempts
}

const getManualRedialCalls = async ({ companyId, callResultsToRedial, redialMaxAttempts }: {
    companyId: string,
    callResultsToRedial: CallResult[],
    redialMaxAttempts: { [key in CallResult]?: number }
}) => {
    const today = new Date()
    const endOfDay = new Date(today.setHours(24, 0, 0, 0))
    const historyQuery = query(
        collection(db, `companies/${companyId}/callHistory`),
        and(
            or(...callResultsToRedial.map(result => where("result", "==", result))),
            // 次回架電予定日が設定されているもの
            and(
                where("nextCallDate", "<=", endOfDay),
                where("nextCallDate", ">", new Date(0)),
            ),
            where("nextCalled", "==", false),
        )
      );
    const querySnapshot = await getDocs(historyQuery);
    const histories: CallHistoryRecord[] = []
    querySnapshot.forEach((doc) => {
        histories.push({ ...doc.data(), id: doc.id } as CallHistoryRecord)
    });

    // redialAttemptsを数える
    const historiesWithRedialAttempts = await Promise.all(histories.map(async record => ({
        ...record,
        redialAttempts: await countRedialAttempts(companyId, record)
    })))

    // redialAttempts以上再架電されたものは除外
    // nextCallHistoryIdから参照
    const isAttemptedMaxTimes = await Promise.all(historiesWithRedialAttempts.map((record) => {
        if(!redialMaxAttempts[record.result] || redialMaxAttempts[record.result] <= 0) {
            throw new Error(`redialMaxAttempts must be greater than 0 (key: ${record.result}, value: ${redialMaxAttempts[record.result]})`)
        }
        return redialMaxAttempts[record.result] <= record.redialAttempts
    }))
    const filtered = historiesWithRedialAttempts.filter((_, index) => !isAttemptedMaxTimes[index])

    return filtered
}

const getAiRedialCalls = async ({ companyId, callResultsToRedial, redialIntervalDays, redialMaxAttempts }: {
    companyId: string,
    callResultsToRedial: Array<CallResult>,
    redialIntervalDays: { [key in CallResult]?: number },
    redialMaxAttempts: { [key in CallResult]?: number }
}) => {
    const today = new Date()
    const endOfDay = new Date(today.setHours(24, 0, 0, 0))
    const histories: CallHistoryRecord[] = []

    await Promise.all(callResultsToRedial.map(async (target) => {
        if(!redialIntervalDays[target] || redialIntervalDays[target] <= 0) {
            throw new Error(`redialIntervalDays must be greater than 0 (key: ${target}, value: ${redialIntervalDays[target]})`)
        }
        const createdAtEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - redialIntervalDays[target] + 5)
        const historyQuery = query(
            collection(db, `companies/${companyId}/callHistory`),
            and(
                where("result", "==", target),
                where("nextCalled", "==", false),
                or(
                    // 次回架電予定日が設定されているもの
                    and(
                        where("nextCallDate", "<=", endOfDay),
                        where("nextCallDate", ">", new Date(0)),
                    ),
                    // または指定日数以上経過しているもの
                    where("createdAt", "<=", createdAtEnd)
                )
            )
        )
        const querySnapshot = await getDocs(historyQuery);
        
        querySnapshot.forEach((doc) => {
            histories.push({ ...doc.data(), id: doc.id } as CallHistoryRecord)
        });
    }))

    // redialAttemptsを数える
    const historiesWithRedialAttempts = await Promise.all(histories.map(async record => ({
        ...record,
        redialAttempts: await countRedialAttempts(companyId, record)
    })))

    // redialAttempts以上再架電されたものは除外
    // nextCallHistoryIdから参照
    const isAttemptedMaxTimes = await Promise.all(historiesWithRedialAttempts.map((record) => {
        if(!redialMaxAttempts[record.result] || redialMaxAttempts[record.result] <= 0) {
            throw new Error(`redialMaxAttempts must be greater than 0 (key: ${record.result}, value: ${redialMaxAttempts[record.result]})`)
        }
        return redialMaxAttempts[record.result] <= record.redialAttempts
    }))
    const filtered = historiesWithRedialAttempts.filter((_, index) => !isAttemptedMaxTimes[index])

    return filtered
}

export { getManualRedialCalls, getAiRedialCalls }