import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material"

type Props = {
  loading: boolean,
  message?: string
}

const LoadingBackdrop = ({ loading, message }: Props) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <Stack>
        <CircularProgress color="inherit" sx={{ mx: "auto" }} />
        {
          message && (
            <Typography sx={{ mx: "auto", mt: 1, px: 4 }} maxWidth={400}>
              { message }
            </Typography>
          )
        }
      </Stack>
    </Backdrop>
  )
}

export default LoadingBackdrop