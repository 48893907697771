import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CallResultSummary from "./CallResultSummary";
import {
  ExpandMore,
  PhoneDisabledOutlined,
  UpgradeOutlined,
  VerticalAlignBottomOutlined,
} from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import "./CallProcessCard.css";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice";
import { deleteCallProcess } from "@/store/callSlice";
import { useSelector } from "react-redux";
import useAxiosWithIdToken from "@/hooks/useAxiosWithIdToken";
import axios from "axios";
import CallProcess, { PROCESS_STATUS } from "@/models/CallProcess";
import callStateBgColors from "@/styles/callStateColors";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";

type Props = {
  callProcess: CallProcess;
  docId: string;
};

const CallProcessCard = ({ callProcess, docId }: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const users = useCompanyUsers();
  const dispatch = useDispatch<AppDispatch>();
  const [accordion, setAccordion] = useState(false);
  const [loading, setLoading] = useState(false);
  const axiosWithId = useAxiosWithIdToken();
  const sitting = useMemo(
    () =>
      callProcess.uids.includes(user.uid) &&
      user.callState !== "AWAY" &&
      user.callState !== "HOPE_AWAY",
    [user.callState]
  );
  const handleDeleteClick = () => {
    if (user.role !== "ADMIN" && user.role !== "SV") {
      throw new Error(user.role + " cannot delete processes");
    }

    const deleteProcess = async () => {
      dispatch(
        setLoadingBackdrop({ key: "CallProcessCardDeleteProcess", state: true })
      );
      try {
        const res = await axiosWithId.get(
          import.meta.env.VITE_NGROK_URL + "/killcall/" + docId,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
            },
          }
        );
        dispatch(
          setLoadingBackdrop({
            key: "CallProcessCardDeleteProcess",
            state: false,
          })
        );
        dispatch(
          setSnackbar({
            text: `「${callProcess.name}」へ削除リクエストを送信しました。現在通話中のコールが全て完了すると削除されます。`,
            open: true,
            severity: "success",
          })
        );
      } catch (e) {
        console.error(e);
        console.log("deleted process");
        dispatch(
          setSnackbar({
            text: `「${callProcess.name}」へリクエストを送信する際にエラーが発生しました。`,
            open: true,
            severity: "error",
          })
        );
      }
    };
    deleteProcess();
  };
  // 実際はプロセスへユーザーを割り当てるAPIを叩く
  const handleSitDownClick = useCallback(async () => {
    if (sitting) return;

    // 離席状態から戻る
    if (user.callState === "AWAY") {
      try {
        setLoading(true);
        await axiosWithId.post(
          import.meta.env.VITE_NGROK_URL + "/back/" + user.uid,
          ""
        );
        dispatch(
          setSnackbar({
            text: `「${callProcess.name}」に着席しました。`,
            open: true,
            severity: "success",
          })
        );
      } catch (e) {
        console.error();
        dispatch(
          setSnackbar({
            text: `「${callProcess.name}」へ着席する際にエラーが発生しました。`,
            open: true,
            severity: "error",
          })
        );
      } finally {
        setLoading(false);
      }
    }
  }, [callProcess.name, sitting]);
  // 離席希望を送信
  const handleLeaveClick = useCallback(async () => {
    setLoading(true);
    try {
      await axiosWithId.post(
        import.meta.env.VITE_NGROK_URL + "/away/" + user.uid,
        ""
      );
      dispatch(
        setSnackbar({
          text: `「${callProcess.name}」へ離席リクエストを送信しました。割り当てられているコールが全て完了すると離席状態になります。(残り${user.assignedCallsNumber}個)`,
          open: true,
          severity: "success",
        })
      );
    } catch (e) {
      console.error(e);
      dispatch(
        setSnackbar({
          text: `「${callProcess.name}」へ離席リクエストを送信する際にエラーが発生しました。`,
          open: true,
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  }, [callProcess.name]);
  const progress = useMemo(
    () =>
      100 *
      (callProcess.callCount /
        (callProcess.callCount + callProcess.remainingCallCount)),
    [callProcess]
  );

  const avatarElements = useMemo(() => {
    const aiAvatars = Array.from({ length: callProcess.callAiNum }, (_, i) => (
      <Grid item xs={1} key={i}>
        <Stack>
          <Avatar
            sx={{
              width: 36,
              height: 36,
              mx: "auto",
              bgcolor: "#1E88E5",
              animation: "glow 1s infinite alternate",
            }}
          >
            AI
          </Avatar>
          <Typography fontSize="0.80rem">AI (架電)</Typography>
        </Stack>
      </Grid>
    ));
    const userAvatars = callProcess.uids
      .filter((uid) => users[uid])
      .map((uid, i) => (
        <Grid item xs={1} key={i + 10000}>
          <Stack>
            <Avatar
              sx={{
                width: 36,
                height: 36,
                mx: "auto",
                bgcolor: callStateBgColors[users[uid]?.callState],
              }}
            >
              {users[uid].name}
            </Avatar>
            <Typography fontSize="0.80rem">{users[uid].name}</Typography>
          </Stack>
        </Grid>
      ));
    return aiAvatars.concat(userAvatars);
  }, [callProcess.uids, users]);

  return (
    <Card
      className="call-process-card"
      sx={{
        minWidth: 500,
        minHeight: 200,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        overflow: "visible",
      }}
    >
      <Box display="flex">
        <Card
          sx={{
            bgcolor: "#2296EA",
            width: 196,
            py: 2,
            borderRadius: 0,
            borderBottomRightRadius: 12,
            borderTopLeftRadius: 12,
          }}
          elevation={0}
        >
          <Typography variant="h6" color="#ffffff">
            {callProcess.name || "(名前なし)"}
          </Typography>
          <Box display="flex" gap={2} mt={1} justifyContent="center">
            {(user.role === "SV" || user.role === "ADMIN") && (
              <Tooltip
                title={
                  callProcess.status === PROCESS_STATUS.Aborting
                    ? `「${callProcess.name}」へ削除リクエストを送信しました。現在通話中のコールが全て完了すると削除されます。`
                    : "プロセスを削除します。"
                }
              >
                {/* disabled になっている Button に Tooltip を表示するために span が必要
                    https://stackoverflow.com/questions/61115913/is-it-possible-to-render-a-tooltip-on-a-disabled-material-ui-button-within-a-but */}
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClick}
                  >
                    {callProcess.status === PROCESS_STATUS.Aborting
                      ? "削除 (再試行)"
                      : "削除"}
                  </Button>
                </span>
              </Tooltip>
            )}
            {callProcess.status != PROCESS_STATUS.Aborting &&
              (sitting ? (
                <Button
                  variant="contained"
                  color="white"
                  sx={{ fontWeight: 600 }}
                  onClick={handleLeaveClick}
                  disabled={
                    !callProcess.uids.includes(user.uid) ||
                    user.callState === "HOPE_AWAY" ||
                    loading
                  }
                  startIcon={<UpgradeOutlined></UpgradeOutlined>}
                >
                  離席
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="white"
                  sx={{ fontWeight: 600 }}
                  onClick={handleSitDownClick}
                  disabled={
                    (Boolean(user.assignedPid) &&
                      !callProcess.uids.includes(user.uid)) ||
                    user.callState === "HOPE_AWAY" ||
                    loading
                  }
                  startIcon={
                    <VerticalAlignBottomOutlined></VerticalAlignBottomOutlined>
                  }
                >
                  着席
                </Button>
              ))}
          </Box>
        </Card>
        <Stack pt={1}>
          <Box
            display="flex"
            mb={1}
            mx={4}
            gap={1.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ flexGrow: 1, maxWidth: 210 }}
            ></LinearProgress>
            <Typography>
              {callProcess.callCount}/
              {callProcess.callCount + callProcess.remainingCallCount}
            </Typography>
          </Box>
          <CallResultSummary
            callCount={callProcess.callCount}
            connectionCount={callProcess.answerCount}
            appointmentCount={callProcess.appointmentCount}
          ></CallResultSummary>
        </Stack>
      </Box>
      <Box
        sx={{
          position: "relative",
          overflow: "visible",
          flexGrow: 1,
          bgcolor: accordion ? "#ffffff" : "",
        }}
      >
        <Accordion
          sx={{ position: "absolute", top: 0, height: "100%", width: "100%" }}
          expanded={accordion}
          onChange={() => setAccordion(!accordion)}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ height: "100%", width: "100%" }}
          >
            <Grid container columns={8} px={2}>
              {avatarElements.slice(0, 8).map((avatar, i) => avatar)}
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#ffffff",
              pr: 4,
              pt: 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              boxShadow:
                "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
            }}
          >
            <Grid container columns={8} pl={2} pr={3} rowGap={1.5}>
              {avatarElements.length > 8 &&
                avatarElements.slice(8).map((avatar, i) => avatar)}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Card>
  );
};

export default CallProcessCard;
