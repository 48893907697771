import { useState, useMemo, useEffect, useCallback } from 'react'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { DSBreadcrumbs } from '@/components/DSBreadcrumbs'
import {  PhoneOutlined, Backspace, CallEndOutlined } from '@mui/icons-material'
import formatJapanesePhoneNumber from '@/utils/formatJapanesePhoneNumber'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import useAxiosWithIdToken from '@/hooks/useAxiosWithIdToken'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '@/store/commonSlice'
import { Call } from '@twilio/voice-sdk'
import { useCompanyUsers } from '@/hooks/useCompanyUsers'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { updateDocument } from '@/google/firestore'
import { setCall } from '@/store/callSlice'

const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'backspace', 0];
let intervalId = null

const ManualCall = () => {
  const [prevCallSids, setPrevCallSids] = useState([])    // クエリパラメータで指定
  const [phoneNumber, setPhoneNumber] = useState('')
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('')
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
  const [calling, setCalling] = useState(false)
  const [answered, setAnswered] = useState(false)
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const callDevice = useSelector((state: RootState) => state.call.callDevice)
  const axiosWithIdToken = useAxiosWithIdToken()
  const uid = useSelector((state: RootState) => state.user.uid)
  const dispatch = useDispatch<AppDispatch>()
  const users = useCompanyUsers()
  const navigate = useNavigate()
  const location = useLocation()
  const { tenantId } = useParams()
  const breadcrumbRefs = useMemo(() => ([
    { title: "手動コール" }
  ]), []);

  const handleNumberClick = (num) => {
    if (phoneNumber.length < 15) {
      setPhoneNumber(prevNum => prevNum + num)
    }
  };

  const handleBackspace = () => {
    setPhoneNumber(prevNum => prevNum.slice(0, -1))
  };

  // NOT_ASSIGNEDでない場合はホーム画面へ遷移 (同時に通話が発生することを避けるため)
  useEffect(() => {
    if (users[uid] && users[uid].callState !== "NOT_ASSIGNED") {
      navigate(`/${tenantId}`)
    }
  }, [users, uid])

  useEffect(() => {
    const formatted = formatJapanesePhoneNumber(phoneNumber)
    if(formatted === false) {
      setInvalidPhoneNumber(true)
      setFormattedPhoneNumber(phoneNumber)
      return
    }
    setInvalidPhoneNumber(false)
    setFormattedPhoneNumber(formatted)
  }, [phoneNumber])

  useEffect(() => {
    const q = new URLSearchParams(location.search)
    const initialPhoneNumber = q.get("p")
    const prevCallSids = (q.get("ids")||"").split(",")

    if(initialPhoneNumber)
      setPhoneNumber(initialPhoneNumber)
    if(prevCallSids)
      setPrevCallSids(prevCallSids)
    console.log(prevCallSids)
  }, [location])

  const handleStartCall = useCallback(async () => {
    if (phoneNumber) {
      try {
        setCalling(true)
        await updateDocument(`/users/${uid}`, { manualCalling: true })
        await axiosWithIdToken.post(`${import.meta.env.VITE_NGROK_URL}/manual-call/${companyId}/${phoneNumber}`, {
          uid, prevCallSids: prevCallSids || []
        });
      } catch (error) {
        dispatch(setSnackbar({ open: true, severity: 'error', message: '発信に失敗しました。' }))
      }
    } else {
      dispatch(setSnackbar({ open: true, severity: 'error', message: '電話番号を入力しました。' }))
    }
  }, [phoneNumber, companyId, uid])

  const handleEndCall = () => {
    callDevice.disconnectAll()
  }

  // callDeviceとcallInstanceのハンドラ登録
  useEffect(() => {
    if(!callDevice)
      return
    callDevice.removeAllListeners("incoming")
    callDevice.on("incoming", (call: Call) => {
      dispatch(setCall(call))
      call.accept()
      console.log("move to callscreen (manualcall handler)")
      navigate(`/${tenantId}/callscreen?m=true`)
      
      intervalId = setInterval(() => {
        const status = call.status()
        if(status === "closed") {
          setCalling(false)
          setAnswered(false)
          clearInterval(intervalId)
        }
      }, 1000)

      call.on("connection", () => {
        console.log("call connected")
        setCalling(false)
        setAnswered(true)
      })
      
      call.on("cancel", () => {
        console.log("call canceled")
        call.disconnect()
        setCalling(false)
        setAnswered(false)
      })
  
      call.on("error", () => {
        console.log("call error")
        call.disconnect()
        setCalling(false)
        setAnswered(false)
      })
  
      call.on("disconnected", () => {
        console.log("call disconnected")
        setCalling(false)
        setAnswered(false)
      })
    })
    callDevice.on("accept", (call: Call) => {
      console.log("accepttttttt")
    })
  }, [callDevice])

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Box sx={{
          bgcolor: calling ? "#444" : "#D3D3D3",
          p: 6,
          pt: 8,
          mx: "auto",
          borderRadius: 2,
          width: 300
        }}>
          <Typography sx={{ mb: 6, mt: -6 }} color="#fff" fontSize="0.9rem">
            { calling ? "通話中" : "　" }
          </Typography>
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: 4,
              ml: -5,
              mr: -5,
              wordBreak: 'break-all'
            }}
            color={calling ? "#fff" : "#333"}
          >
            {formattedPhoneNumber || '　'}
          </Typography>
          <Grid container spacing={2}>
            {buttons.map((btn, index) => (
              <Grid item xs={4} key={index}>
                {btn === 'backspace' ? (
                  <Button
                    variant="contained"
                    onClick={handleBackspace}
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      height: 60,
                      bgcolor: '#808080',
                      '&:hover': {
                        bgcolor: '#707070',
                      },
                      borderRadius: '50%',
                    }}
                  >
                    <Backspace />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => handleNumberClick(btn)}
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      height: 60,
                      fontSize: 24,
                      bgcolor: '#808080',
                      '&:hover': {
                        bgcolor: '#707070',
                      },
                      borderRadius: '50%',
                    }}
                  >
                    {btn}
                  </Button>
                )}
              </Grid>
            ))}
          </Grid>
          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              height: 48,
              bgcolor: calling ? "#ef5350" : '#FFA07A',
              '&:hover': {
                bgcolor: calling ? "#e53935" : '#FF8C61',
              },
              borderRadius: 10,
              width: 100
            }}
            onClick={calling ? handleEndCall : handleStartCall}
          >
            {
              calling ? <CallEndOutlined fontSize="large" sx={{ color: "#fff" }} /> :
              <PhoneOutlined fontSize="large" />
            }
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ManualCall;