import { Card, Typography } from "@mui/material"

const CallProcessPlaceHolder = () => {
  return (
    <Card sx={{ minWidth: 550, minHeight: 200, borderRadius: 3, opacity: 0.4, alignItems: "center", display: "flex", justifyContent: "center" }}>
      <Typography color="grey">
        現在稼働中のプロセスはありません。
      </Typography>
    </Card>
  )
}

export default CallProcessPlaceHolder