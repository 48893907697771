
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { callResultColors } from "@/models/CallHistory"
import { useMemo } from "react"
import { useCallResultLabels } from "@/hooks/useCallResultLabels"

type Props = {
  data: Array<{
    id: number | string, value: number, label: string
  }>
}

const CallStatsPieChartLegend = ({ data }: Props) => {
  const callResultLabels = useCallResultLabels()
  const sorted = useMemo(() => {
    return [...data].sort((a, b) => {
      return b.value - a.value
    })
  }, [data])
  return (
    <TableContainer sx={{ maxHeight: 324 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>通話結果</TableCell>
            <TableCell>結果数</TableCell>
            <TableCell>割合</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted.map(({ id, label, value }) => (
            <TableRow key={id}>
              <TableCell>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: callResultColors[id as keyof typeof callResultLabels],
                      marginRight: 1
                    }}
                  />
                  <Typography variant="body2">{label}</Typography>
                </Stack>
              </TableCell>
              <TableCell>{value}</TableCell>
              <TableCell>{value ? ((value / sorted.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(2) : 0}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CallStatsPieChartLegend