import { useCallListMetadata } from "@/hooks/useCallList";
import { useCallResultLabels } from "@/hooks/useCallResultLabels";
import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { useScripts } from "@/hooks/useScripts";
import formatTimestamp from "@/utils/formatTimestamp";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, jaJP } from "@mui/x-data-grid";
import React from "react";

const RedialAccordion = React.memo(
  ({
    columns,
    group,
    groupKey,
    checked,
    handleChangeRadioButton: handleChangeRadioButton,
  }: {
    columns: GridColDef[];
    group: any[];
    groupKey: string;
    checked: boolean;
    handleChangeRadioButton: (key: string) => void;
  }) => {
    const callResultLabels = useCallResultLabels();
    const users = useCompanyUsers();
    const callListMetadata = useCallListMetadata({ disableLoading: true });
    const scriptMetadata = useScripts({ disableLoading: true });

    return (
      <Accordion sx={{ "& .MuiAccordionSummary-content": { my: 0 } }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid
            container
            columns={12}
            minWidth={640}
            sx={{ textAlign: "left", whiteSpace: "nowrap" }}
          >
            <Grid item xs={1} display="flex" alignItems="center" gap={1}>
              <Radio
                size="small"
                checked={checked}
                onChange={() => handleChangeRadioButton(groupKey)}
                onClick={(e) => e.stopPropagation()}
              ></Radio>
            </Grid>
            <Grid item xs={3} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                予定日:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {group[0].nextCallDate?.toMillis()
                  ? group[0].nextCallDate?.toDate().toLocaleDateString()
                  : "---"}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                コールリスト:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {callListMetadata[group[0].callListId]?.callListName}
              </Typography>
            </Grid>
            <Grid item xs={4} display="flex" alignItems="center" gap={1}>
              <Typography sx={{ opacity: 0.5 }} fontSize="0.8rem">
                スクリプト:
              </Typography>
              <Typography
                fontSize="0.9rem"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {scriptMetadata[group[0].scriptId]?.name}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            columns={columns}
            rows={group.map((history, k) => ({
              id: k,
              companyName: history.companyName,
              phoneNumber: history.phoneNumber,
              createdAt: formatTimestamp(history.createdAt),
              nextCallDate: formatTimestamp(history.nextCallDate),
              callResult: callResultLabels[history.result] || history.result,
              operatorId: users[history.operatorId]?.name || "---",
              redialAttempts: history.redialAttempts + "回",
            }))}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            hideFooter={group.length <= 10}
            pageSizeOptions={[10, 20, 50, 100]}
            rowHeight={36}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
          ></DataGrid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

export default RedialAccordion;
