import { CallResult } from "@/models/CallHistory"
import axios from "axios"
import { getIdToken } from "@/google/auth"

const getCallStats = async ({ companyId, period, commodityId, scriptId, callListId }: {
    companyId: string
    period: number
    commodityId?: string
    scriptId?: string
    callListId?: string
}) => {
    const res = await axios.post(import.meta.env.VITE_AGGEREGATE_CALL_RESULT_FOR_PERIOD_URL, {
        companyId, period, commodityId, scriptId, callListId
    })
    return res.data
}

const callStatsPeriods = [
    "TODAY",
    "THIS_WEEK",
    "LAST_WEEK",
    "THIS_MONTH",
    "LAST_MONTH",
    "THIS_YEAR",
] as const
const callStatsPeriodLabels = {
    TODAY: "今日",
    THIS_WEEK: "今週",
    LAST_WEEK: "先週",
    THIS_MONTH: "今月",
    LAST_MONTH: "先月",
    THIS_YEAR: "今年",
} as const

type CallStats = {
    [key in CallResult | "TOTAL"]: number
} & { final: boolean }

type CallStatsPeriod = typeof callStatsPeriods[number]

const showingCallResultOptions: Array<CallResult | "OTHERS"> = [
    "TOSSUP_APPOINTMENT",
    "FINISHED_ON_AI",
    "UNREACHABLE",
    "DENIED",
    "TOSSUP_ABSENT",
    "TOSSUP_NURTURING_A",
    "TOSSUP_NURTURING_B",
    "TOSSUP_NURTURING_C",
    
    "AUTOMATED",
    "IMPOSSIBLE",
    "DENIED_ON_AI",
    "AI_NURTURING",
    "SIMULTANEOUS_TOSSUP",
    "TOSSUP_NOT_REGISTERED",

    // "OTHERS"
] as const

type CallStatsFilter = {
    companyId: string
    period: CallStatsPeriod
    commodityId?: string
    scriptId?: string
    callListId?: string
    daysOfWeek?: string[],
    showingCallResults?: Array<typeof showingCallResultOptions[number]>
}


const getCallStatsByInterval = async ({ companyId, period, commodityId, scriptId, callListId, daysOfWeek }: CallStatsFilter) => {
    const res = await axios.post(
        import.meta.env.VITE_AGGREGATE_CALL_STATS_BY_INTERVAL_URL,
        {
            companyId,
            period,
            commodityId,
            scriptId,
            callListId,
            daysOfWeek,
        },
        {
            headers: {
                Authorization: `Bearer ${await getIdToken()}`,
            }
        }
    )
    return res.data as {
        [label: string]: {
            [key in CallResult]: number
        } & {
            TOTAL: number
            final: boolean
        }
    }
}

export { getCallStats, callStatsPeriodLabels, getCallStatsByInterval, callStatsPeriods, showingCallResultOptions }
export type { CallStats, CallStatsFilter, CallStatsPeriod }
