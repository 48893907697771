// import DSBreadcrumbs from "@/components/DSBreadcrumbs"
import Papa from "papaparse"
import { Box, Button, Container, Paper, Popover, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as yup from "yup"
import { AppDispatch, RootState } from "@/store/store"
import { deleteCallListItems, updateCallList, updateLocalCallListItems } from "@/store/callSlice"
import { useParams } from "react-router-dom"
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { Add, Edit, FileDownload } from "@mui/icons-material"
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice"
import { CallListItem } from "@/models/CallList"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCallListMetadata } from "@/hooks/useCallList"
import { Timestamp} from "firebase/firestore"
import CallListTable from "@/features/CallList/CallListTable"
import { getRandomDocId } from "@/google/firestore"
import CallListItemCursor from "@/features/CallList/CallListItemCursor"
import { GridSortModel } from "@mui/x-data-grid"
import CallListMetadataEdit from "@/features/CallList/CallListMetadataEdit"
import { useCallResultLabels } from "@/hooks/useCallResultLabels"
import { getIdToken } from "@/google/auth"

const keyLabels = {
  companyName: "会社名",
  phoneNumber: "電話番号"
} as const

const pageSize = 100

let cursor: CallListItemCursor | null = null

const CallListDetail = () => {
  const urlParameteres = useParams()
  const callResultLabels = useCallResultLabels()
  const callListId = urlParameteres.id
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const dispatch = useDispatch<AppDispatch>()
  const callListItemsStore = useSelector((state: RootState) => state.call.callListItems)
  const callListItems = useMemo(() =>
    callListItemsStore && callListItemsStore[callListId] ? Object.values(callListItemsStore[callListId]) : [],
    [callListItemsStore, callListId]
  )
  const callListMetadata = useCallListMetadata()
  const [rowCount, setRowCount] = useState(0)
  const [newCallListMetadata, setNewCallListMetadata] = useState<{
    memo: string,
    callListName: string,
    searchWord: string
  } | null>(null)
  const [newOptionalInfoOrder, setNewOptionalInfoOrder] = useState<string[]>([])
  
  // callListMetadata, cursorの初期化
  useEffect(() => {
    if(!callListMetadata[callListId])
      return

    setNewCallListMetadata({
      memo: callListMetadata[callListId].memo,
      callListName: callListMetadata[callListId].callListName,
      searchWord: callListMetadata[callListId].searchWord
    })
    setNewOptionalInfoOrder(callListMetadata[callListId].optionalInfoOrder || [])

    cursor = new CallListItemCursor({
      companyId: companyId,
      callListId,
      itemsPerPage: pageSize
    })

    // 最初の1ページフェッチ
    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "HistoryInitFetch", state: true }))
      setRowCount(await cursor.totalNum())
      const results = await cursor.fetchNext(0)
      if(results) {
        dispatch(updateLocalCallListItems(results))
      }
      dispatch(setLoadingBackdrop({ key: "HistoryInitFetch", state: false }))
    }
    fetch()
  }, [callListMetadata, callListId])

  const handleChangePage = useCallback(async (newPage: number) => {
    if(!callListId)
      return
    if(!cursor)
      throw new Error("cursor is not initialized")
    
    dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: true }))

    const results = await cursor.fetchNext(newPage)
    if(results)
      dispatch(updateLocalCallListItems(results))

    dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: false }))
  }, [callListId, cursor, dispatch])

  const handleChangeSort = useCallback(async (sortModel: GridSortModel) => {
    cursor = new CallListItemCursor({
      companyId,
      callListId,
      itemsPerPage: 20,
      sort: sortModel.length ? {
        field: sortModel[0].field as keyof CallListItem,
        order: sortModel[0].sort
      } : undefined
    })
    
    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleChangeSort", state: true }))
    try {
      const results = await cursor.fetchNext(0)
      dispatch(updateLocalCallListItems(results))
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoadingBackdrop({ key: "CallListDetailHandleChangeSort", state: false }))
    }
  }, [callListId, companyId])

    /**
     * ファイル名を生成する
     * @param {string} callListName コールリスト名
     * @returns {string} ファイル名
     */
    const generateFileName = (callListName: string) => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${callListName}-${year}${month}${day}.csv`;
    };


    /**
     * データをCSV形式でエクスポートする
     * @param {string} callListId コールリストID
     * @param {string} companyId カンパニーID
     * @returns CSVファイル
     */
    const exportCsv = async (callListId: string, companyId: string, callListName: string) => {
      const url = `${import.meta.env.VITE_EXPORT_RESULT_API_URL}/callListItems/csv?companyId=${companyId}&callListId=${callListId}`;
      try {
        const token = await getIdToken();
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "text/csv",
             "Authorization": `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("CSVのエクスポートに失敗しました。");
        }
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = generateFileName(callListName);
        a.click();
        URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error(error);
        dispatch(
          setSnackbar({
            open: true,
            text: "CSVのエクスポートに失敗しました。",
            severity: "error"
          })
        );
      }
    };

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(() => ([
    {
      title: "リスト管理",
      navigateTo: `/${companyId}/calllist`
    },
    {
      title: "詳細" 
    }
  ]), [companyId])

  // コールリストの編集
  const [editMode, setEditMode] = useState(false)
  const [rowSelection, setRowSelection] = useState([])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openPopover = useMemo(() => Boolean(anchorEl), [anchorEl])
  const popoverId = useMemo(() => openPopover ? "simple-popover" : undefined, [openPopover])
  const oldColumnOrder = useMemo(() => callListMetadata[callListId]?.optionalInfoOrder, [callListMetadata, callListId])
  const columnOrderChanged = useMemo(() => (
    oldColumnOrder &&
    newOptionalInfoOrder &&
    oldColumnOrder.join() !== newOptionalInfoOrder.join()
  ), [oldColumnOrder, newOptionalInfoOrder])

  const handleDeleteClick = useCallback(async () => {
    const indexSet = new Set(rowSelection)
    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleDeleteClick", state: true }))
    
    try {
      await dispatch(deleteCallListItems({ companyId, callListId, indexes: Array.from(indexSet).map(v => v - 1) }))
      dispatch(setSnackbar({
        open: true,
        text: `${rowSelection.length}件の項目を削除しました。`,
        severity: "success"
      }))
    } catch (e) {
      console.error(e)
      dispatch(setSnackbar({
        open: true,
        text: "削除中にエラーが発生しました。",
        severity: "error"
      }))
    } finally {
      dispatch(setLoadingBackdrop({ key: "CallListDetailHandleDeleteClick", state: false }))
      setRowSelection([])
    }
  }, [rowSelection, callListItems, callListMetadata, callListId])

  const handleFinishEditClick = useCallback(() => {
    setNewCallListMetadata(null)
    setEditMode(false)
  }, [])

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const itemSchema = useMemo(() => {
    const schema: {
      [key: string]: yup.StringSchema
    } = {}
    if(callListMetadata && callListMetadata[callListId] && callListMetadata[callListId].optionalInfoOrder) {
      callListMetadata[callListId].optionalInfoOrder.forEach(key => {
        schema[key] = yup.string()
          .optional()
          .max(200, `${key}は200文字以内で入力してください。`)
      })
    }
    schema.phoneNumber = yup.string()
        .required("電話番号は必須です。")
        .matches(/^0\d{9,11}$/, "有効な電話番号ではありません。")
    schema.companyName = yup.string()
        .required("会社名は必須です。")

    return yup.object(schema)
  }, [callListMetadata, callListId])
  
  const forms = useMemo(() => {
    const defaultForms = [
      { key: "phoneNumber", name: "電話番号" },
      { key: "companyName", name: "会社名" },
    ]
    if(!callListMetadata || !callListMetadata[callListId] || !callListMetadata[callListId].optionalInfoOrder)
      return defaultForms
    return  defaultForms.concat(callListMetadata[callListId].optionalInfoOrder
      .map(key => ({
        key,
        name: key
      })))
  }, [callListMetadata])

  const { handleSubmit, formState: { errors }, control, formState, reset } = useForm({
    mode: "all",
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      phoneNumber: "",
      companyName: ""
    },
    resolver: yupResolver(itemSchema)
  })

  const handleOnValidSubmit = useCallback(async (data) => {
    const optionalInfo = { ...data }
    delete optionalInfo.phoneNumber
    delete optionalInfo.companyName
    const id = getRandomDocId(`/companies/${companyId}/callListItems_v2`)
    const newItem: CallListItem = {
      id,
      index: callListItems.length,
      callListId,
      phoneNumber: data.phoneNumber || "",
      companyName: data.companyName || "",
      optionalInfo
    }
    console.log(newItem)
    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleValidSubmit", state: true }))
    await dispatch(updateCallList({
      companyId: companyId,
      id: callListId,
      items: [newItem],
      metadata: {
        ...callListMetadata[callListId],
        updatedAt: Timestamp.now()
      }
    }))
    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleValidSubmit", state: false }))
    dispatch(setSnackbar(({
      open: true,
      text: "正常に項目を追加しました。",
      severity: "success"
    })))
    reset()
  }, [callListItems, callListMetadata, callListId, companyId])

  const uploadNewCallListMetadata = useCallback(async () => {
    dispatch(setLoadingBackdrop({ key: "CallListDetailUploadNewCallListMetadata", state: true }))

    await dispatch(
      updateCallList({
        metadata: {
          ...callListMetadata[callListId],
          memo: newCallListMetadata?.memo,
          callListName: newCallListMetadata?.callListName,
          searchWord: newCallListMetadata?.searchWord
        },
        id: callListId,
        companyId: companyId
      })
    )

    dispatch(setSnackbar({
      open: true,
      text: "正常にコールリストの情報を更新しました。",
      severity: "success"
    }))
    dispatch(setLoadingBackdrop({ key: "CallListDetailUploadNewCallListMetadata", state: false }))
    setNewCallListMetadata({
      ...newCallListMetadata,
      memo: undefined,
      callListName: undefined,
      searchWord: undefined
    })
  }, [newCallListMetadata, callListId, callListMetadata, companyId])

  const handleApplyColumnOrder = useCallback(async () => {
    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleApplyColumnOrder", state: true }))

    await dispatch(updateCallList({
      metadata: {
        ...callListMetadata[callListId],
        optionalInfoOrder: newOptionalInfoOrder
      },
      id: callListId,
      companyId: companyId
    }))

    dispatch(setLoadingBackdrop({ key: "CallListDetailHandleApplyColumnOrder", state: false }))
    dispatch(setSnackbar({ text: "列の順序を更新しました。", open: true, severity: "success" }))
    setNewCallListMetadata({ ...newCallListMetadata })
    setNewOptionalInfoOrder([])
  }, [newCallListMetadata, callListMetadata, callListId, companyId])
  
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack flexWrap="wrap" alignContent="center" mx="auto" mt={4}>
        <Stack gap={2}>
          <CallListMetadataEdit
            callListId={callListId}
            form={newCallListMetadata}
            onFormChange={setNewCallListMetadata}
            onClickChange={uploadNewCallListMetadata}
          ></CallListMetadataEdit>
          <Box display="flex" justifyContent="flex-start" gap={2} mt={2} mb={2}>
            {
              !editMode && <Button
                variant="contained"
                color="primary"
                onClick={() => setEditMode(true)}
                startIcon={<Edit></Edit>}
                disabled={!callListItems || callListItems.length === 0}
              >
                リストの編集
              </Button>
            }
            {
              editMode && (
                <>
                  <Button
                    variant="outlined"
                    onClick={handleFinishEditClick}
                  >
                    終了
                  </Button>
                  <Button
                    variant="contained"
                    aria-describedby={popoverId}
                    onClick={e => setAnchorEl(e.currentTarget)}
                    startIcon={<Add></Add>}
                  >
                    追加
                  </Button>
                  {/* <Button     // indexがずれてしまうため一時的にコメントアウト
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClick}
                    disabled={!rowSelection.length}
                    startIcon={<Delete></Delete>}
                  >
                    削除
                  </Button> */}
                  <Button
                    variant="contained"
                    disabled={!columnOrderChanged}
                    onClick={handleApplyColumnOrder}
                  >
                    列の順序を適用
                  </Button>
                </>
              )
            }
            {
              !editMode && <Button
              variant="outlined"
              onClick={async () => {
                await exportCsv(callListId, companyId, callListMetadata[callListId].callListName);
              }}
              startIcon={<FileDownload></FileDownload>}
              >
                エクスポート
              </Button>
            }
          </Box>
        </Stack>

        <Popover
          id={popoverId}
          anchorEl={anchorEl}
          open={openPopover}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Paper sx={{ px: 4, py: 2 }}>
            <Typography mb={2} textAlign="left">新しい項目を追加</Typography>
            <form onSubmit={handleSubmit(handleOnValidSubmit)}>
              <Stack gap={1}>
                {
                  forms.map(form => (
                    <Controller key={form.key} name={form.key} control={control} render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        label={keyLabels[form.name] || form.name}
                        error={form.key in errors}
                        helperText={errors[form.key]?.message}
                      />
                    )} />
                  ))
                }
              </Stack>
              <Box display="flex" justifyContent="center" gap={2} mt={3}>
                <Button onClick={handlePopoverClose} variant="outlined">
                  キャンセル
                </Button>
                <Button type="submit" variant="contained" disabled={!formState.isValid}>
                  追加
                </Button>
              </Box>
            </form>
          </Paper>
        </Popover>

        <Box width="100%">
          <CallListTable 
            // ↓ dev環境の不正なデータ対応用
            optionalInfoOrder={callListMetadata[callListId]?.optionalInfoOrder?.filter(key => key !== "phoneNumber" && key !== "companyName" && key !== "index")}
            callListItems={callListItems}
            checkboxSelection={editMode}
            rowSelectionModel={rowSelection}
            onRowSelectionModelChange={(model) => setRowSelection(model)}
            onChangeOptionalInfoOrder={(optionalInfoOrder) => setNewOptionalInfoOrder(optionalInfoOrder)}
            showCallResults={true}
            editMode={editMode}
            handleChangePage={handleChangePage}
            handleChangeSort={handleChangeSort}
            rowCount={rowCount}
            pageSize={pageSize}
          />
        </Box>
      </Stack>
    </Container>
  )
}

export default CallListDetail