import { Box, Grid } from "@mui/material"

export const CallHistoryHeader = () => {
  return (
    <Grid container columns={15} sx={{ px: 5, whiteSpace: "nowrap", fontSize: "0.875rem" }}>
      <Grid item xs={1}>発信/着信</Grid>
      <Grid item xs={3}>電話番号</Grid>
      <Grid item xs={3}>コール先</Grid>
      <Grid item xs={2}>コール日時</Grid>
      <Grid item xs={2}>コール結果</Grid>
      <Grid item xs={2}>通話時間</Grid>
      <Grid item xs={1}>録音</Grid>
      <Grid item xs={1}>詳細</Grid>
    </Grid>
  )
}