import { useCallListMetadata } from "@/hooks/useCallList"
import softStyleProps from "@/styles/softStyleProps"
import { Button, FormControl, InputLabel, Stack, TextField, Typography } from "@mui/material"
import React from "react"

const CallListMetadataEdit = React.memo(({ callListId, form, onFormChange, onClickChange }: {
  callListId: string,
  form: { callListName: string, memo: string, searchWord: string },
  onFormChange: (form: { callListName: string, memo: string, searchWord: string }) => void,
  onClickChange: () => void
}) => {
  const callListMetadata = useCallListMetadata()

  return <>
    <Stack direction="row" maxWidth={400} gap={2}>
      <FormControl size="small" fullWidth>
        <InputLabel shrink>コールリスト名</InputLabel>
        <TextField
          size="small"
          sx={{ ...softStyleProps.sx, width: "100%" }}
          value={form?.callListName}
          onChange={(event) => onFormChange({ ...form, callListName: event.target.value })}
        ></TextField>
      </FormControl>
      <Button
        variant="contained"
        disabled={(form?.callListName === undefined) || (form?.callListName === callListMetadata[callListId]?.callListName)}
        onClick={onClickChange}
      >
        変更
      </Button>
    </Stack>
    <Stack direction="row" maxWidth={400} gap={2}>
      <FormControl size="small" fullWidth>
        <InputLabel shrink>コールリストメモ</InputLabel>
        <TextField
          size="small"
          sx={{ ...softStyleProps.sx, width: "100%" }}
          placeholder="(メモ無し)"
          value={form?.memo}
          onChange={(event) => onFormChange({ ...form, memo: event.target.value })}
        ></TextField>
      </FormControl>
      <Button
        variant="contained"
        disabled={(form?.memo === undefined) || (form?.memo === callListMetadata[callListId]?.memo)}
        onClick={onClickChange}
      >
        変更
      </Button>
    </Stack>
    <Stack direction="row" gap={2} alignItems="center">
      <Stack direction="row" width={400} gap={2}>
        <FormControl size="small" fullWidth>
          <InputLabel shrink>検索ワード</InputLabel>
          <TextField
            size="small"
            sx={{ ...softStyleProps.sx, width: "100%" }}
            placeholder="(ワード無し)"
            value={form?.searchWord}
            onChange={(event) => onFormChange({ ...form, searchWord: event.target.value })}
          ></TextField>
        </FormControl>
        <Button
          variant="contained"
          disabled={(form?.searchWord === undefined) || (form?.searchWord === callListMetadata[callListId]?.searchWord)}
          onClick={onClickChange}
        >
          変更
        </Button>
      </Stack>
      <Typography fontSize="0.8rem" color="#666">
        ※ トスアップ画面で「(会社名) + (検索ワード)」の検索結果が表示されます。
      </Typography>
    </Stack>
  </>
})

export default CallListMetadataEdit