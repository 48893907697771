import { Fade, Stack } from "@mui/material"
import { ReactNode } from "react"

const RelativeBackdrop = ({ open, children }: {
  open: boolean,
  children: ReactNode
}) => {
  return (
    <Fade in={open}>
      <Stack
        sx={{ position: "absolute", background: "rgba(0, 0, 0, 0.25)", top: 0, left: 0, right: 0, bottom: 0 }}
        justifyContent="center" 
      >
        { children }
      </Stack>
    </Fade>
  )
}

export default RelativeBackdrop