// import DSBreadcrumbs from "@/components/DSBreadcrumbs"
import { FileOpen, FileUploadOutlined } from "@mui/icons-material"
import { Alert, Backdrop, Box, Button, CircularProgress, Container, Stack, TextField, Typography } from "@mui/material"
import parseFile from "@/features/CallList/CallListFileParser"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { CallListItem, CallListMetadata } from "@/models/CallList"
import { useSelector, useDispatch } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { updateLocalCallListItems, updateLocalCallListMetadata } from "@/store/callSlice"
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { setSnackbar } from "@/store/commonSlice"
import { useParams } from "react-router-dom";
import CallListTable from "@/features/CallList/CallListTable"
import { Timestamp } from "firebase/firestore"
import { addDocumentWithDocId } from "@/google/firestore"

const emptyCallListItem: CallListItem = {
  id: "",
  index: 0,
  callListId: "",
  companyName: "",
  phoneNumber: ""
}
const initialCallListItems = Array.from(Array(10), (v, k) => ({ ...emptyCallListItem, id: String(k), index: k }))
const softStyleInputProps = {
  size: "small",
  sx: {
    bgcolor: "#ffffff",
    "& .MuiOutlinedInput-notchedOutline" : {
      border: "none"
    },
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "4px"
  },
} as const

const CallListAdd = () => {
  const inputRef = useRef(null)
  const { tenantId } = useParams()
  const uid = useSelector((state: RootState) => state.user.uid)
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const [uploading, setUploading] = useState(false)
  const [inputFile, setInputFile] = useState<null | File>(null)
  const [isValidFile, setIsValidFile] = useState(false)
  const [callListName, setCallListName] = useState("call list")
  const [callListItems, setCallListItems] = useState<CallListItem[]>(initialCallListItems)
  const [callListMemo, setCallListMemo] = useState("")
  const [optionalInfoOrder, setOptionalInfoOrder] = useState<string[]>([])
  const [uploadProgress, setUploadProgress] = useState(0)
  const dispatch = useDispatch<AppDispatch>()

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    console.log(e.target.files)
    if(files && files[0]) {
      setInputFile(files[0])
    }
  }

  // ファイル参照時の処理
  useEffect(() => {
    if(!inputFile)
      return
    const handleFile = async () => {
      try {
        const callListItems = await parseFile(inputFile)
        setCallListName(inputFile.name)
        setCallListItems(callListItems)
        setIsValidFile(true)
      } catch(e) {
        if(e instanceof Error) {
          dispatch(setSnackbar({ text: e.message, open: true, severity: "error" }))
        } else {
          dispatch(setSnackbar({ text: "ファイルのアップロード中にエラーが発生しました。", open: true, severity: "error" }))
        }
        setIsValidFile(false)
        setInputFile(null)
      }
    }
    handleFile()
  }, [inputFile])

  // コールリストのアップロード
  const uploadCallList = useCallback(async () => {
    const timestamp = Timestamp.now()
    const metadata: CallListMetadata = {
        callListName,
        creatorId: uid,
        id: "",
        memo: callListMemo,
        optionalInfoOrder,
        createdAt: timestamp,
        updatedAt: timestamp,
    }

    const metadataDocRef = await addDocumentWithDocId(`/companies/${companyId}/callListMetadata`, metadata)
    metadata.id = metadataDocRef.id
    const items = await Promise.all(callListItems.map(async (item, index) => {
        item.callListId = metadataDocRef.id
        item.lastCallResult = "NEVER_CALLED"
        const ref = await addDocumentWithDocId(`/companies/${companyId}/callListItems_v2`, item)
        item.id = ref.id
        setUploadProgress((v) => v + 1)
        return item
    }))
    dispatch(updateLocalCallListMetadata([metadata]))
    dispatch(updateLocalCallListItems(items))
  }, [callListItems, callListMemo, callListName, companyId, dispatch, optionalInfoOrder, uid])

  const handleClickUpload = useCallback(async () => {
    if(!isValidFile)
      return
    
    setUploading(true)
    try {
      await uploadCallList()
      dispatch(setSnackbar({
        text: `コールリスト "${callListName}" を正常にアップロードしました。`,
        severity: "success",
        open: true
      }))
      setIsValidFile(false)
      setInputFile(null)
      setCallListItems(initialCallListItems)
    } catch (e) {
      console.error(e)
      dispatch(setSnackbar({
        text: "コールリストのアップロード中にエラーが発生しました。",
        severity: "error",
        open: true
      }))
    } finally {
      setUploading(false)
      setUploadProgress(0)
    }
  }, [isValidFile, callListName, dispatch, uploadCallList])

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(() => ([
    {
      title: "コール先設定",
      navigateTo: `/${tenantId}/calllist`
    },
    {
      title: "追加"
    }
  ]), [])

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack alignContent="center" mx="auto" mt={2} px={4}>
        <Box display="flex" justifyContent="left">
          <Stack minWidth={380}>
            <Box display="flex" justifyContent="flex-start" gap={3}>
              <Button sx={{ width: 170 }} color="primary" variant="contained" component="label">
                <FileOpen></FileOpen>
                <Typography ml={1}>ファイル参照</Typography>
                <input hidden ref={inputRef} type="file" accept=".csv" onChange={onChangeFile}></input>
              </Button>
              <Button color="success" variant="contained" component="label" disabled={!isValidFile} onClick={handleClickUpload}>
                <FileUploadOutlined></FileUploadOutlined>
                アップロード
              </Button>
            </Box>

            <TextField
              {...softStyleInputProps}
              sx={{ ...softStyleInputProps.sx, mt: 4, maxWidth: 440 }}
              label="コールリスト名"
              size="small"
              onChange={(e) => setCallListName(e.target.value)}
              disabled={!isValidFile} multiline maxRows={4}
              value={inputFile ? callListName : ""}
            ></TextField>

            <TextField
              {...softStyleInputProps}
              sx={{ ...softStyleInputProps.sx, mt: 2, maxWidth: 440 }}
              label="コールリストのメモを追加 (任意)"
              size="small"
              onChange={(e) => setCallListMemo(e.target.value)}
              disabled={!isValidFile} multiline maxRows={4}
              value={callListMemo}
            ></TextField>
          </Stack>

          <Box ml={4} mt="auto">
            <Alert severity="info" variant="outlined" sx={{ textAlign: "left" }}>
                <Typography fontWeight="bold" fontSize="0.8rem" display="inline">ファイル1行目</Typography>: ヘッダー<br></br>
                <Typography fontWeight="bold" fontSize="0.8rem" display="inline">ファイル1列目, 2列目</Typography>: 会社名, 電話番号<br></br>
                <Typography fontWeight="bold" fontSize="0.8rem" display="inline">ファイル3列目以降</Typography>: 任意の情報 (7列目まで通話画面に表示, 並び替え可能)
            </Alert>
          </Box>
        </Box>

        <CallListTable
          editMode={true}
          callListItems={callListItems}
          onChangeOptionalInfoOrder={setOptionalInfoOrder}
        />
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uploading}
      >
        <Stack>
          <CircularProgress color="inherit" sx={{ mx: "auto" }} />
          <Typography sx={{ mx: "auto", mt: 1, px: 4 }} maxWidth={400}>
            アップロード中... ({uploadProgress}/{callListItems.length})
          </Typography>
        </Stack>
      </Backdrop>
    </Container>
  )
}

export default CallListAdd