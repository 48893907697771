import { User } from "@/models/User"
import { app } from "./firebase"
import { Transaction, TransactionOptions, addDoc, collection, deleteDoc, doc, getDoc, getDocs, getFirestore,query, runTransaction, setDoc, where, initializeFirestore, updateDoc, Timestamp, Query, or } from "firebase/firestore"

initializeFirestore(app, {
    ignoreUndefinedProperties: true
});
const db = getFirestore(app)
// db.settings({
//     ignoreUndefinedProperties: true,
// })
const usersRef = collection(db, "/users")
const companiesRef = collection(db, "/companies")
const getDocRef = (path: string, segments: string[] = []) =>
    doc(db, path, ...segments)

const getCollectionRef = (path: string, segments: string[] = []) =>
    collection(db, path, ...segments)

const getRandomDocId = (collectionPath: string) =>
    doc(collection(db, collectionPath)).id

const getSnapshot = (path: string, segments: string[] = []) =>
    getDoc(doc(db, path, ...segments))

const getDocData = async (path: string) =>
    (await getSnapshot(path)).data()

const getSnapshots = async (q: string | Query) => {
    if(typeof q === "string")
        return (await getDocs(collection(db, q))).docs
    else
        return (await getDocs(q)).docs
}

const addDocument = (collectionPath: string, data: object) =>
    addDoc(collection(db, collectionPath), data)
// データにそれ自身のdoc idを付与して追加

const updateDocument = (path: string, data: object) =>
    updateDoc(doc(db, path), data)

const addDocumentWithDocId = async (collectionPath: string, data: object) => {
    const newRef = doc(collection(db, collectionPath))
    await setDoc(newRef, { ...data, id: newRef.id })
    return newRef
}

const getRandomDocRef = async (collectionPath: string) => {
    return doc(collection(db, collectionPath))
}

const setDocDB = (path: string, docId: string, data: object) =>
    setDoc(doc(db, path, docId), data)

const deleteDocDB = (collectionPath: string, docId: string) => {
    const docRef = doc(db, collectionPath, docId)
    return deleteDoc(docRef)
}

const runTransactionDB = <T>(updateFunction: (transaction: Transaction) => Promise<T>, options?: TransactionOptions) => {
    return runTransaction<T>(db, updateFunction, options)
}

const getCompanyUsers = async (companyId: string) => {
    const usersQuery = query(collection(db, "users"), where("companyId", "==", companyId))
    const querySnapshot = await getDocs(usersQuery)
    const users = []
    querySnapshot.forEach(doc => {
        users.push(doc.data())
    })
    return users as User[]
}

export {
  usersRef,
  companiesRef,
  getDocRef,
  getRandomDocId,
  getCollectionRef,
  getRandomDocRef,
  getSnapshot,
  getDocData,
  getSnapshots,
  getCompanyUsers,
  addDocument,
  addDocumentWithDocId,
  updateDocument,
  setDocDB as setDoc,
  deleteDocDB as deleteDoc,
  runTransactionDB as runTransaction,
  db
}