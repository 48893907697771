import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type SnackbarState = {
    text: string,
    open: boolean,
    severity: "success" | "error",
    autoHideDuration: number,
    variant: "filled" | ""
}

type PopupInstanceState = {
    url: string,
    name: string,
    features: string,
} | null;

type CommonState = { 
    loadingBackdrop: {
        [key: string]: boolean
    },
    aiCallLoadingBackdrop: boolean,
    snackbar: SnackbarState,
    callStateSubscribed: boolean,
    popupInstance: PopupInstanceState
}

const initialState: CommonState = {
    loadingBackdrop: {},
    aiCallLoadingBackdrop: false,
    snackbar: {
        text: "",
        open: false,
        severity: "success",
        autoHideDuration: 5000,
        variant: "filled"
    },
    callStateSubscribed: false,
    popupInstance: null
}

function getCaller() {
    const error = new Error();
    const stack = error.stack || '';
    return stack
    const stackLines = stack.split('\n');
    const callerIndex = stackLines.findIndex(line => line.includes('getCaller')) + 2;
    if (stackLines[callerIndex]) {
      return stackLines[callerIndex].trim();
    }
    return 'Unknown';
  }

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setLoadingBackdrop: (state, action: PayloadAction<{key: string, state: boolean}>) => {
            const newLoadingBackdrop = {
                ...state.loadingBackdrop,
                [action.payload.key]: action.payload.state
            }
            state.loadingBackdrop = newLoadingBackdrop;
        },
        setSnackbar: (state, action: PayloadAction<Partial<SnackbarState>>) => {
            state.snackbar = {
                ...state.snackbar,
                ...action.payload
            };
        },
        setCallStateSubscribed: (state, action: PayloadAction<boolean>) => {
            state.callStateSubscribed = action.payload;
        },
    },
});

export const { setLoadingBackdrop, setSnackbar, setCallStateSubscribed } = commonSlice.actions;
export default commonSlice.reducer;
