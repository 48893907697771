import { getCollectionRef, getDocRef } from "@/google/firestore"
import CallProcess, { PROCESS_STATUS } from "@/models/CallProcess"
import { deleteLocalCallProcess, getCallProcesses, updateCallProcesses } from "@/store/callSlice"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { onSnapshot, Unsubscribe } from "firebase/firestore"
import { useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

let unsubscribes: Array<Unsubscribe> = []

export const useCallProcesses = () => {
    const user = useSelector((state: RootState) => state.user)
    const callProcesses = useSelector((state: RootState) => state.call.callProcesses)
    const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
    const dispatch = useDispatch<AppDispatch>()
    const fetchedPids = useMemo(() => 
        Object.keys(callProcesses)
    , [callProcesses])

    useEffect(() => {
        if(!user.isSignedIn)
            return
        if(unsubscribes.length)
            return

        const fetch = async () => {
            dispatch(setLoadingBackdrop({ key: "useCallProcessesFetch", state: true }))
            await dispatch(getCallProcesses({ companyId: user.companyId }))
            dispatch(setLoadingBackdrop({ key: "useCallProcessesFetch", state: false }))
        }
        fetch()
    }, [isSignedIn])

    useEffect(() => {
        if(!user.isSignedIn)
            return
        if(unsubscribes.length)
            return
        // ドキュメントが追加/更新/削除された場合にstoreへ反映
        unsubscribes = [onSnapshot(getCollectionRef(`/companies/${user.companyId}/callProcesses`), async (snapshot) => {
            snapshot.docChanges().forEach(change => {
                if(change.type === "added" || change.type === "modified") {
                    const process = change.doc.data() as CallProcess
                    dispatch(setLoadingBackdrop({ key: "useCallProcessesAddedOrModified", state: true }))
                    dispatch(updateCallProcesses([change.doc.id, process]))
                    dispatch(setLoadingBackdrop({ key: "useCallProcessesAddedOrModified", state: false }))

                    // コールプロセスを削除しないが非表示にするための対応
                    if (
                      [PROCESS_STATUS.Completed, PROCESS_STATUS.Aborted].includes(
                        process.status
                      )
                    ) {
                      dispatch(
                        setLoadingBackdrop({
                          key: "useCallProcessesRemoved",
                          state: true,
                        })
                      );
                      dispatch(deleteLocalCallProcess(change.doc.id));
                      dispatch(
                        setLoadingBackdrop({
                          key: "useCallProcessesRemoved",
                          state: false,
                        })
                      );
                    }
                }
                if(change.type === "removed") {
                    dispatch(setLoadingBackdrop({ key: "useCallProcessesRemoved", state: true }))
                    dispatch(deleteLocalCallProcess(change.doc.id))
                    dispatch(setLoadingBackdrop({ key: "useCallProcessesRemoved", state: false }))
                }
            })
        })]
        
        // return () => {
        //     unsubscribes.forEach(unsubscribe => unsubscribe())
        //     unsubscribes = []
        //     fetchCount = {}
        // }
    }, [isSignedIn, fetchedPids.length])

    return callProcesses
}