import { Skeleton, Box, Divider, Paper, Stack, Table, TableBody, TableCell, TableRow, Typography,  Button } from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { CallPad } from "@/features/CallScreen/CallScreenCallPad"
import { CallScreenResultRegister, Result } from "@/features/CallScreen/CallScreenResultRegister"
import { useDispatch } from "react-redux"
import { CallHistoryRecord, CallResult } from "@/models/CallHistory"
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice"
import { getDocData, updateDocument } from "@/google/firestore"
import useAxiosWithIdToken from "@/hooks/useAxiosWithIdToken"
import { useCallListMetadata } from "@/hooks/useCallList"
import { useTranscripts } from "@/hooks/useTranscript"
import { useScriptLines } from "@/hooks/useScriptLines"
import TwilioCallInfo from "@/models/TwilioCall"
import formatTimestamp from "@/utils/formatTimestamp"
import ringingAudioFilePath from "@/assets/ringing.mp3"
import { setCall } from "@/store/callSlice"
import { sendCallResult } from "@/features/callResultRegister/sendCallResult"
import CallListItemCursor from "@/features/CallList/CallListItemCursor"
import { CallListItem } from "@/models/CallList"
import { setMissedManualInboundCallOn } from "@/store/userSlice"
import { useCookies } from "react-cookie"

let intervalId: NodeJS.Timeout | null = null;
let sendedManualInboundCallResult = false

const CallScreen = () => {
  const [lastCallHistory, setLastCallHistory] = useState<CallHistoryRecord | null>(null)
  const [callResultRegister, setCallResultRegister] = useState(false)
  const [tossUpCallInfo, setTossUpCallInfo] = useState<TwilioCallInfo | null>(null)
  const [displayLeftColumn, setDisplayLeftColumn] = useState(false)
  const [callIsAccepted, setCallIsAccepted] = useState(false)
  const audioElementRef = useRef<HTMLAudioElement | null>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  
  const { fromManualCall} = useMemo(() => {
    const query = new URLSearchParams(location.search)
    return {
      fromManualCall: query.get("m") === "true"
    }
  }, [location.search])
  const [cookies, setCookie, removeCookie] = useCookies()

  let callListItemCursor: CallListItemCursor | null = null
  
  const user = useSelector((state: RootState) => state.user)
  const callInstance = useSelector((state: RootState) => state.call.call)
  const [callListItem, setCallListItem] = useState<CallListItem | null>(null)
  const callListMetadata = useCallListMetadata()
  const axiosWithId = useAxiosWithIdToken()
  const transcript = useTranscripts(tossUpCallInfo?.callSid)
  const scriptLines = useScriptLines(tossUpCallInfo?.scriptId)
  const transcriptArray = useMemo(() => {
    if(!transcript)
      return []
    return transcript.split(/\n/).slice(1).filter(str => str).map(str => str.split(":"))
  }, [transcript])
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  useEffect(() => {
    if (!callListItem || !tossUpCallInfo || !callListMetadata || !callListMetadata[tossUpCallInfo.callListId]) {
      setSearchQuery(null)
      return
    }
    
    setSearchQuery(callListItem?.companyName.replace(/\s+/g, "+") + "+" + callListMetadata[tossUpCallInfo.callListId].searchWord)
  }, [callListItem, callListMetadata, tossUpCallInfo])

  const Iframe = useMemo(() => {
    console.log({ searchQuery });

    if(searchQuery === null) {
      return () => (
        <Stack
        width="100%"
        height="100%"
          justifyContent="center"
          alignItems="center"
          >
          <Typography sx={{ opacity: 0.4 }}>
            (コール先情報なし)
          </Typography>
        </Stack>
      )
    }

    return () => (
      <Box
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <Box
          sx={{ bgcolor: "#e5e5e5", p: 2 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
          borderBottom="1px solid #e0e0e0"
        >
          <Button
            variant="contained"
            color="primary"
            href={encodeURI(`https://www.google.com/search?q=${searchQuery}&igu=1`)}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ m: 2, textTransform: "none" }}
          >
            Google検索: {searchQuery}
          </Button>
          <Typography
            textAlign="center"
          >
            (reCAPTCHAが表示される場合はボタンを押すと検索できます)
          </Typography>
        </Box>
        <iframe
          width="100%"
          height="100%"
          src={encodeURI(`https://www.google.com/search?q=${searchQuery}&igu=1`)}
        ></iframe>
      </Box>
    )
  }, [searchQuery])
  const { tenantId } = useParams();

  const handleEndCall = () => {
    setCookie(user.call?.callSid, "closed")
    setCallResultRegister(true)
  }

  // callListItemのフェッチ
  useEffect(() => {
    if(!tossUpCallInfo)
      return

    console.log("tossUpCallInfo: ", tossUpCallInfo)

    const fetch = async () => {
      callListItemCursor = new CallListItemCursor({
        companyId: user.companyId,
        callListId: tossUpCallInfo.callListId,
        itemsPerPage: 1,
        callListItemIndex: tossUpCallInfo.callListIndex
      })
  
      const items = await callListItemCursor.fetchNext()
      if(!items.length)
        throw new Error("callListItem not found")
      items[0].companyName = items[0].companyName || "未入力"
      items[0].phoneNumber = items[0].phoneNumber || "未入力"
      setCallListItem(items[0])
    }
    
    fetch()
  }, [tossUpCallInfo])

  // コール結果の登録
  const handleResultSubmit = useCallback(async (submit: Result) => {
    if(!submit.callResult)
      throw new Error("empty call result")

    dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: true }))
    try {
      await sendCallResult({
        user,
        fromManualCall,
        callResult: submit.callResult as CallResult,
        nextCallDate: submit.nextCallDate,
        callMemo: submit.callMemo,
        tossUpCallInfo,
        axiosWithId
      })
      dispatch(setSnackbar({
        text: "正常にコール結果を登録しました。ホームへ戻ります。",
        severity: "success",
        open: true
      }))
    } catch(e) {
      console.error(e)
      dispatch(setSnackbar({
        text: "コール結果の登録に失敗しました。ホームへ戻ります。",
        severity: "error",
        open: true
      }))
    } finally {
      dispatch(setCall(null))
      dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }))
      if(user.call?.manual) {  // 受電/手動通話状態の解除 
        updateDocument(`/users/${user.uid}`, {
          manualCalling: false,
          ongoingManualCallSid: ""
        })
      }
      removeCookie(user.call?.callSid)
    }    
  }, [dispatch, navigate, user.companyId, user.uid, user.call, tossUpCallInfo, callListItem, callInstance])

  // バックエンド側でCALL_IN_PRGORESS以外に変更された場合、ホーム画面へ遷移
  useEffect(() => {
    if(!user.isSignedIn || user.callState === "CALL_IN_PROGRESS")
      return
    navigate(`/${tenantId}/`)
  }, [user.isSignedIn, user.callState])

  // コール先情報のフェッチ, コールバックの登録
  useEffect(() => {
    if(!callInstance)
      return

    console.log(callInstance?.customParameters)
    console.log(callInstance?.outboundConnectionId)
    console.log("incoming callSid:" + callInstance?.parameters?.CallSid)

    if(intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }

    intervalId = setInterval(async () => {
      const status = callInstance.status()

      if(status === "closed") {
        setCookie(user.call?.callSid, "closed")
        callInstance.disconnect()
        setCallResultRegister(true)
        if(audioElementRef.current)
          audioElementRef.current.pause()
        clearInterval(intervalId)
        intervalId = null
      }

      // 手動受電時に通話に出れなかった場合、
      // 結果登録画面をスキップしてホーム画面へ遷移し通知を表示
      console.log(user.call?.manual, status, callIsAccepted, sendedManualInboundCallResult)
      if(user.call?.manual && status === "closed" && !callIsAccepted && !sendedManualInboundCallResult) {
        sendedManualInboundCallResult = true
        dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: true }))
        await sendCallResult({
          user,
          fromManualCall: true,
          callResult: "MANUAL_INBOUND_UNREACHABLE",
          tossUpCallInfo,
          axiosWithId
        })
        dispatch(setLoadingBackdrop({ key: "callResultSubmit", state: false }))
        dispatch(setMissedManualInboundCallOn(true))
        sendedManualInboundCallResult = false
      } else {
        dispatch(setMissedManualInboundCallOn(false))
      }      
    }, 1000)

    callInstance.on("connection", () => {
      console.log("call connected")
    })

    callInstance.on("cancel", () => {
      console.error("call canceled")
      // callInstance.disconnect()
      setCallResultRegister(true)
    })

    callInstance.on("error", () => {
      console.error("call error")
      // callInstance.disconnect()
      setCallResultRegister(true)
    })

    callInstance.on("disconnected", () => {
      console.log("call disconnected")
      setCallResultRegister(true)
    })
  }, [callInstance, user.call, callIsAccepted])

  // callsからphoneNumberに一致するcallを探して取得
  // 通話終了時に着信を終了 (鳴っていれば)
  useEffect(() => {
    if(!user.isSignedIn)
      return

    const fetch = async () => {
      //すみません🙇くそ実装です...
      // TODO この糞実装をなんとかする
      // やってること: user.callをuserのサブコレクションのcallとして作成しています。
      // user/callをcompany/callHistoryに統合したため。
      const call :TwilioCallInfo = {
        companyName: user.call.companyName || "",
        direction: user.call.direction,
        manual: user.call.manual,
        callSid: user.call.callSid,
        uid: user.uid,
        companyId: user.companyId,
        scriptId: user.call.scriptId,
        callListId: user.call.callListId,
        callListIndex: user.call.callListIndex,
        phoneNumber: user.call.phoneNumber,
        prevCallSids: user.call.prevCallSids || [],
        status: "TOSSUP",
      }
      
      return call;
    }
    
    // 通話画面、検索結果画面の制御
    fetch().then((callInfo) => {
      setTossUpCallInfo(callInfo)
      console.log(callInfo, user.callState, callInstance)
      if(callInfo.prevCallSids.length) {
        getDocData(`/companies/${user.companyId}/callHistory/${callInfo.prevCallSids[0]}`).then((doc) => {
          setLastCallHistory(doc as CallHistoryRecord)
        }).catch((e) => {
          console.error(e)
        })
      }
      if(callInstance) {
        // Twilio.Callのインスタンスが存在すれば通話画面表示
        setDisplayLeftColumn(true)

        // 通話が切断されたら着信音中断 (鳴っていれば)
        callInstance.on("disconnect", async () => {
          if(audioElementRef.current)
            audioElementRef.current.pause()
        })
      }
      if(user.callState === "CALL_IN_PROGRESS" && cookies[user.call.callSid] === "closed") {
        // cookieですでに通話終了後となっている場合、結果登録画面へ
        // (手動通話または受電時にユーザーが画面をリロードした/閉じた)
        // (ユーザーが結果登録をせずにcallscreenをリロードした/閉じた)
        // (callInstanceの有無で判定するとuser.callStateとの同期が難しいので、一旦cookieで対応)
        setDisplayLeftColumn(true)
        setCallResultRegister(true)
      }
    }).catch((e) => {
      console.error(e)
    })
  }, [user.isSignedIn, user.callState, callInstance])

  /**
   * URLかどうかを判定
   * @param {string} string - 判定対象の文字列 
   * @returns {boolean} URLかどうか
   */
  const isUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // トスアップ通知用音声の再生
  useEffect(() => {
    const ring = async () => {
      if(!callInstance || callInstance.status() === "closed")
        return
      if(audioElementRef.current && !audioElementRef.current.paused)
        return

      try {
        const audio = new Audio(ringingAudioFilePath)
        audio.loop = true
        audio.play()
        audioElementRef.current = audio
      } catch(e) {
        console.error(e)
        return null
      }
    }
    ring()
  }, [])

  const handleOnUnmute = useCallback(() => {
    setCallIsAccepted(true)
    if(audioElementRef.current)
      audioElementRef.current.pause()
  }, [audioElementRef.current])

  return (
    <Box display="flex" height="100vh" width="100%" overflow="hidden">
      <Box width={380}>
        <Stack height="100%">
          {
            !displayLeftColumn ?
            <Skeleton
              variant="rectangular"
              sx={{ width: "100%", height: "100%" }}
              animation="wave"
            ></Skeleton> : <>
              {
                callResultRegister ? 
                  <CallScreenResultRegister
                    callDuration="00:00"
                    onSubmit={handleResultSubmit}
                    manualCalling={user.call?.manual}
                    direction={user.call?.direction}
                  ></CallScreenResultRegister> :
                  <CallPad
                    onEndCall={handleEndCall}
                    onUnmute={handleOnUnmute}
                    phoneNumber={callListItem?.phoneNumber}
                    displayName={callListItem?.companyName} 
                  ></CallPad>
              }
              <Paper sx={{
                bgcolor: "#454545",
                color: "#fff",
                borderRadius: 0,
                p: 4,
                height: 660,
                maxHeight: 660,
                overflowY: "scroll",
                scrollbarColor: "#666666 #454545"
              }}>
                <Stack>
                  <Typography sx={{ pb: 1, px: 1 }} textAlign="left">基本情報</Typography>
                  <Table
                    size="small"
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                        color: "#fff",
                        fontSize: "0.8rem",
                        padding: "2px 12px"
                      }
                    }}
                  >
                    <TableBody>
                      {
                        (!callListItem || !Object.keys(callListItem).length ) &&
                        (!callListMetadata || !callListMetadata[tossUpCallInfo?.callListId]) && (
                          <TableRow >
                            <TableCell
                              colSpan={2}
                              sx={{ textAlign: "center", height: "auto", px: 0 }}
                            >
                              <Typography sx={{ opacity: 0.5, fontSize: "0.85rem", bgcolor: "rgba(255,255,255,0.1)", p: 4 }}>
                                コールリスト情報なし
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      }
                      {
                        callListItem && (
                          <TableRow>
                            <TableCell>会社名</TableCell>
                            <TableCell>                  
                              {isUrl(callListItem?.companyName) ? (
                                <a
                                  style={{ color: 'white', textDecoration: 'none' }} 
                                  onMouseOver={(e) => e.currentTarget.style.color = 'white'} 
                                  onMouseOut={(e) => e.currentTarget.style.color = 'white'}   
                                  onFocus={(e) => e.currentTarget.style.color = 'white'}    
                                  onBlur={(e) => e.currentTarget.style.color = 'white'}  
                                  href={callListItem.companyName}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {callListItem.companyName}
                                </a>
                              ) : (
                                callListItem?.companyName || "─"
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      }
                      {
                        callListMetadata && callListMetadata[tossUpCallInfo?.callListId] &&
                        (callListMetadata[tossUpCallInfo?.callListId].optionalInfoOrder??[]).slice(2,7).map((label) => (
                          <TableRow>
                            <TableCell>{ label }</TableCell>
                            <TableCell sx={{ textAlign: !callListItem?.optionalInfo?.[label] ? "center" : "left" }}>
                              {isUrl(callListItem?.optionalInfo?.[label]) ? (
                                 <a
                                 style={{ color: 'white', textDecoration: 'none' }} 
                                 onMouseOver={(e) => e.currentTarget.style.color = 'white'} 
                                 onMouseOut={(e) => e.currentTarget.style.color = 'white'}   
                                 onFocus={(e) => e.currentTarget.style.color = 'white'}    
                                 onBlur={(e) => e.currentTarget.style.color = 'white'}  
                                 href={callListItem.optionalInfo[label]}
                                 target="_blank"
                                 rel="noopener noreferrer"
                               >
                                  {callListItem.optionalInfo[label]}
                                </a>
                              ) : (
                                callListItem?.optionalInfo?.[label] || "─"
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Stack>
    
                <Divider sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}></Divider>
  
                <Stack gap={1.5} px={1}>
                  <Typography textAlign="left">会話ログ</Typography>
                  {
                    transcriptArray && transcriptArray.length ? 
                    transcriptArray.map(([speeker, text], index) => (
                      <Stack
                        pl={ speeker === "Callee" ? 0 : 4 }
                        pr={ speeker === "Callee" ? 4 : 0 }
                        key={index}
                      >
                        <Stack
                          key={index}
                          direction="row"
                          display="inline-flex"
                          gap={1}
                          bgcolor="rgba(255,255,255,0.1)"
                          ml={ speeker === "Callee" ? 0 : "auto" }
                          mr={ speeker === "Callee" ? "auto" : 0 }
                          py={0.5}
                          px={1}
                        >
                          <Typography
                            fontSize="0.7rem" sx={{ opacity: 0.6 }}
                            textAlign={ speeker === "Callee" ? "left" : "right" }
                            order={ speeker === "Callee" ? 0 : 1 }
                            whiteSpace="nowrap"
                          >
                            { speeker === "Callee" ? "顧客" : "AI" }
                          </Typography>
                          <Typography
                            fontSize="0.8rem"
                            textAlign="left"
                            lineHeight="1.2"
                          >
                            { speeker === "Callee" ? text : scriptLines[text] || text }
                          </Typography>
                        </Stack>
                      </Stack>
                    )) : (
                      <Box bgcolor="rgba(255,255,255,0.05)" p={4}>
                        <Typography fontSize="0.85rem" sx={{ opacity: "0.5" }}>
                          会話ログなし
                        </Typography>
                      </Box>
                    )
                  }
                </Stack>
    
                <Divider sx={{ borderColor: "#fff", opacity: "0.2", my: 4 }}></Divider>
    
                <Stack>
                  <Typography sx={{ pb: 1, px: 1 }} textAlign="left">前回コール</Typography>
                  <Table
                    size="small"
                    sx={{
                      "& .MuiTableCell-root": {
                        borderBottom: "none",
                        color: "#fff",
                        fontSize: "0.8rem",
                        padding: "2px 12px"
                      }
                    }}
                  >
                    <TableBody>
                      {
                        lastCallHistory ? (
                          <TableRow>
                            <TableCell>コール日時</TableCell>
                            <TableCell>{formatTimestamp(lastCallHistory.createdAt)}</TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              sx={{ textAlign: "center", height: "auto", px: 0 }}
                            >
                              <Typography sx={{ opacity: 0.5, fontSize: "0.85rem", bgcolor: "rgba(255,255,255,0.1)", p: 4 }}>
                                前回コール情報なし
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </TableBody>
                  </Table>
                </Stack>
              </Paper>
            </>
          }
        </Stack>
      </Box>
      <Box flexGrow={1}>
        <Iframe />
      </Box>
    </Box>
  )
}

export default CallScreen