import { getDownloadURL, getStorage, ref } from "firebase/storage"
import { app } from "./firebase"

const storage = getStorage(app)

const downloadFromStorage = ({ path, responseType }: { path: string, responseType: XMLHttpRequestResponseType }) =>
    new Promise((resolve, reject) => {
        getDownloadURL(ref(storage, path))
            .then(url => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = responseType;
                xhr.onload = () => {
                    resolve(xhr.response);
                };
                xhr.open('GET', url);
                xhr.send();
            }).catch(e => {
                reject(e)
            })
    })

export { downloadFromStorage }    