import { CallListItem } from "@/models/CallList";
import { convert, detect } from "encoding-japanese";
import { parse } from "papaparse"
import isValidPhoneNumber from "@/utils/isValidPhoneNumber";

const normalizePhoneNumber = (phoneNumber: string) => {
    const trimed = phoneNumber.replace(/[\s()（）-]/g, "")
    if(trimed.match(/^\+81/))
        return trimed.replace("+81", "0")
    if(trimed[0] !== "0")
        return "0" + trimed
    return trimed
}

const parseFile = (file: File) => {
    // 1行目はヘッダなのでスキップ
    const recordStartRowIndex = 1

    // 1列目, 2列目以外のフォーマットは指定しない
    const colIndex = {
        companyName: 0,
        phoneNumber: 1
    }

    return new Promise<CallListItem[]>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const codes = new Uint8Array(e?.target?.result as ArrayBuffer);
        const encoding = detect(codes);
        if(!encoding)
            throw new Error("cannot detect code")
        const unicodeString = convert(codes, {
            to: 'UNICODE',
            from: encoding,
            type: 'string',
        });
        parse(unicodeString, {
            complete: (results) => {
                const data = results?.data as string[][]
                const callListItems: CallListItem[] = []
                
                // 1行目のヘッダを取得 (企業名, 電話番号以外)
                const headers = data[0].slice(2, data[0].length)

                // 2行目以降の各行について処理
                data.slice(recordStartRowIndex, data.length)
                    .forEach((record, i) => {
                        const origPhoneNumber = record[colIndex.phoneNumber]
                        if(!origPhoneNumber) {
                            return
                        }
                        const phoneNumber = normalizePhoneNumber(origPhoneNumber)
                        if(!isValidPhoneNumber(phoneNumber)) {
                            console.error(phoneNumber)
                            reject(new Error(`コールリストに有効ではない電話番号が含まれています。 (${i+1}行目: ${origPhoneNumber || "電話番号未指定"})`));
                        }
                        callListItems.push({
                            id: "",
                            index: i,
                            callListId: "",     // 保存時に変更
                            companyName: record[colIndex.companyName],
                            phoneNumber,
                            // 任意の情報 (キーはヘッダー名をそのまま使用)
                            optionalInfo: Object.fromEntries(headers.filter(header => header).map((header, k) =>
                                ([header, record[k+2] || ""])
                            ))
                        })
                    })
                resolve(callListItems);
            },
            error: (error: Error) => {
                reject(error);
            },
        });
      };
      reader.readAsArrayBuffer(file);
    });
};

export default parseFile