import { getDocData } from "@/google/firestore"
import { Company } from "@/models/Company"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { setCompanyConfig } from "@/store/companySlice"
import { AppDispatch, RootState } from "@/store/store"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

let fetching = false

export const useCompanyConfig = ({ disableLoading = false }: { disableLoading?: boolean } = {}) => {
    const companyId = useSelector((state: RootState) => state.user.companyId)
    const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
    const companyConfig = useSelector((state: RootState) => state.company.config)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const fetch = async () => {
            if(!isSignedIn || fetching)
                return
            if(companyConfig.companyName)
                return
            fetching = true

            if(!disableLoading)
                dispatch(setLoadingBackdrop({ key: "useCompany", state: true }))
        
            const fetchedCompany = await getDocData(`/companies/${companyId}`)
            if(!fetchedCompany)
                throw new Error(`/companies/${companyId} not found`)
            dispatch(setCompanyConfig(fetchedCompany as Company))

            if(!disableLoading)
                dispatch(setLoadingBackdrop({ key: "useCompany", state: false }))
            fetching = false
        }
        fetch()
    }, [dispatch, isSignedIn, companyId, companyConfig.companyName, disableLoading])

    return companyConfig
}