import { downloadFromStorage } from "@/google/storage"
import { CallHistoryRecord } from "@/models/CallHistory"
import formatTimestamp from "@/utils/formatTimestamp"
import { MoreHoriz, PhoneCallback, PhoneForwarded, PlayArrow, QuestionMark, Stop } from "@mui/icons-material"
import { Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "@/store/store"
import { useEffect, useMemo, useRef, useState } from "react"
import { useCallResultLabels } from "@/hooks/useCallResultLabels"

export const CallHistoryEntry = ({ record }: { record: CallHistoryRecord }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useSelector((state: RootState) => state.user)
  const { tenantId } = useParams();
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const callResultLabels = useCallResultLabels()
  const [isPlaying, setIsPlaying] = useState(false)

  /**
   * 通話時間を分と秒に分ける
   * @returns mm分ss秒の形式で返す
   */
  const callDuration = useMemo(() => {
    //通話結果がUNREACHABLEの場合は0分0秒を返す
    if (
      !record.result ||
      ["UNREACHABLE", "NEVER_CALLED"].includes(record.result)
    )
      return "-";
    return `${Math.floor(record.callDuration / 60).toFixed(0)}分${(record.callDuration % 60).toFixed(0)}秒`;
  }, [record.callDuration, record.result]);
  
  const handleTogglePlay = async () => {
    if (isPlaying) {
      audioRef.current?.pause()
      setIsPlaying(false)
    } else {
      try {
        if (!audioRef.current) {
          const blob = await downloadFromStorage({
            path: `company/${user.companyId}/log/audio/${record.id}.wav`,
            responseType: "blob"
          }) as Blob
          const blobUrl = URL.createObjectURL(blob)
          const audio = new Audio(blobUrl)
          audioRef.current = audio
        }
        audioRef.current.play()
        setIsPlaying(true)
      } catch(e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    return () => {
      if(audioRef.current) {
        audioRef.current.pause()
        setIsPlaying(false)
      }
    }
  }, [location])

  useEffect(() => {
    const handleEnded = () => setIsPlaying(false)
    audioRef.current?.addEventListener('ended', handleEnded)
    return () => audioRef.current?.removeEventListener('ended', handleEnded)
  }, [])

  const getCallDirectionIcon = () => {
    switch (record.callDirection) {
      case "INCOMING":
        return <PhoneCallback fontSize="small" htmlColor="#7FB50B" />
      case "OUTGOING":
        return <PhoneForwarded fontSize="small" htmlColor="#E15E14" />
      default:
        return <QuestionMark fontSize="small" color="inherit" />
    }
  }

  const getCallDirectionText = () => {
    switch (record.callDirection) {
      case "INCOMING":
        return "受信"
      case "OUTGOING":
        return "発信"
      default:
        return "その他"
    }
  }

  /**
   * 電話場番号の+81を0に変換する
   * @param {string} phoneNumber 電話番号
   * @returns {string} 変換後の電話番号
   */
  const convertPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/^\+81/, "0")  
  }

  return (
    <Paper sx={{ px: 5, py: 1, borderRadius: 3 }}>
      <Grid container columns={15} spacing={1}>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          <Stack>
            {
              record.callDirection === "INCOMING" ? <PhoneCallback htmlColor="#7FB50B" sx={{ mx: "auto" }} /> :
              record.callDirection === "OUTGOING" ? <PhoneForwarded htmlColor="#E15E14" sx={{ mx: "auto" }} /> :
              <QuestionMark sx={{ mx: "auto" }} color="inherit"></QuestionMark>
            }
            <Typography fontSize="0.875rem">
              { record.callDirection === "INCOMING" ? "受信" : record.callDirection === "OUTGOING" ? "発信" : "その他" }
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <Typography>
            { convertPhoneNumber(record.phoneNumber) }
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="center" justifyContent="center">
          <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            { record.companyName }
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
          <Typography>
            { formatTimestamp(record.createdAt) }
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
          <Typography>
            { callResultLabels[record.result] || record.result }
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
          <Typography>{callDuration}</Typography>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          <IconButton onClick={handleTogglePlay} size="medium" sx={{ mr: 1 }}>
            {isPlaying ? <Stop fontSize="small" /> : <PlayArrow fontSize="small" />}
          </IconButton>
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
          <IconButton onClick={() => navigate(`/${tenantId}/history/${record.id || "xxx"}`)}>
            <MoreHoriz fontSize="large"></MoreHoriz>
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}