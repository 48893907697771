import * as React from 'react';
import {
  Select as BaseSelect,
  selectClasses,
  SelectListboxSlotProps,
  SelectProps,
  SelectRootSlotProps,
} from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { CssTransition } from '@mui/base/Transitions';
import { PopupContext } from '@mui/base/Unstable_Popup';

// export default function UnstyledSelectIntroduction() {
//   return (
//     <Select defaultValue={10}>
//       <Option value={10}>Documentation</Option>
//       <Option value={20}>Components</Option>
//       <Option value={30}>Features</Option>
//     </Select>
//   );
// }

export { SimpleSelect, Option }

type Props<A extends {}, B extends boolean> = SelectProps<A, B> & { size?: "xs" | "md" }

const SimpleSelect = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean,
>(props: Props<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
  const slots = {
    root: StyledButton,
    listbox: AnimatedListbox,
    popup: Popup,
    ...props.slots,
  };

  return <BaseSelect {...props} ref={ref} slots={slots} />;
});

const blue = {
  100: '#DAECFF',
  200: '#99CCF3',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

type ButtonProps<A,B extends boolean> = SelectRootSlotProps<A,B> & { size: "xs" | "md" }

const Button = React.forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
>(
  props: ButtonProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

const StyledButton = styled(Button, { shouldForwardProp: () => true })(
  ({ theme, size = "md" }) => {
    const fontSize = {
      xs: "0.80rem",
      md: "0.875rem"
    }
    const padding = {
      xs: "6px 10px",
      md: "8px 12px"
    }
    const minWidth = {
      xs: "120px",
      md: "160px"
    }
    return `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: ${fontSize[size]};
      box-sizing: border-box;
      min-width: ${minWidth[size]};
      padding: ${padding[size]};
      border-radius: 8px;
      text-align: left;
      line-height: 1;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      position: relative;
      box-shadow: 0px 2px 4px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
      };

      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;

      &:hover {
        background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
        border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      }

      &.${selectClasses.focusVisible} {
        outline: 0;
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
      }

      & > svg {
        font-size: 1rem;
        position: absolute;
        height: 100%;
        top: 0;
        right: 10px;
      }
    `
  }
);

const Listbox = styled('ul')(
  ({ theme }) => {
    return `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.80rem;
      box-sizing: border-box;
      padding: 6px;
      margin: 12px 0;
      min-width: 320px;
      border-radius: 12px;
      overflow: auto;
      outline: 0px;
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 2px 4px ${
        theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
      };
      
      .closed & {
        opacity: 0;
        transform: scale(0.95, 0.8);
        transition: opacity 200ms ease-in, transform 200ms ease-in;
      }
      
      .open & {
        opacity: 1;
        transform: scale(1, 1);
        transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
      }

      .placement-top & {
        transform-origin: bottom;
      }
      
      & {
        overflow-y: scroll;
        max-height: 300px;
        scrollbar-color: #ddd #f6f6f6;
        scrollbar-width: thin;
      }

      .placement-bottom & {
        transform-origin: top;
      }
    `
  }
);

type AnimatedListBoxProps<A, B extends boolean> = SelectListboxSlotProps<A, B> & { size: "xs" | "md" }

const AnimatedListbox = React.forwardRef(function AnimatedListbox<
  Value extends {},
  Multiple extends boolean,
>(
  props: AnimatedListBoxProps<Value, Multiple>,
  ref: React.ForwardedRef<HTMLUListElement>,
) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
    );
  }

  const verticalPlacement = popupContext.placement.split('-')[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const Option = styled(BaseOption)(
  ({ theme }) => {
    return `
      list-style: none;
      padding: 8px;
      border-radius: 8px;
      cursor: default;

      &:last-of-type {
        border-bottom: none;
      }

      &.${optionClasses.selected} {
        background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
        color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
      }

      &.${optionClasses.highlighted} {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      }

      &:focus-visible {
        outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      }
      
      &.${optionClasses.highlighted}.${optionClasses.selected} {
        background-color: ${theme.palette.mode === 'dark' ? blue[900] : blue[100]};
        color: ${theme.palette.mode === 'dark' ? blue[100] : blue[900]};
      }

      &.${optionClasses.disabled} {
        color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
      }

      &:hover:not(.${optionClasses.disabled}) {
        background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      }
    `
  }
);

const Popup = styled('div')`
  z-index: 1;
`;