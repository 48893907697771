import { UserCallState } from "@/models/User"

const callStateBgColors: {
    [state in UserCallState["callState"]]: string
} = {
    CALL_IN_PROGRESS: "#E53935",    // red
    ACTIVE: "#EF6C00",              // orange
    ASSIGNED: "#FBC02D",            // yellow
    NOT_ASSIGNED: "#7CB342",        // green
    AWAY: "#1E88E5",                // blue
    HOPE_AWAY: "#1E88E5",           // blue
    NOT_LOGGED_IN: "#757575",       // grey
} as const

export default callStateBgColors