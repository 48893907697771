import { Box, Divider, Typography } from "@mui/material";

type Props = {
  callCount: number,
  connectionCount: number,
  appointmentCount: number
}

const CallResultSummary = ({ callCount, connectionCount, appointmentCount }: Props) => (
  <Box display="flex" justifyContent="center" minWidth={324}>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography sx={{ px: 2, textAlign: "left" }}>コール数</Typography>
      <Divider sx={{ mt: 1 }}></Divider>
      <Typography sx={{ px: 2 }} variant="h4" fontWeight="900">{callCount}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography sx={{ px: 2, textAlign: "left" }}>通電数</Typography>
      <Divider sx={{ mt: 1 }}></Divider>
      <Typography sx={{ px: 2 }} variant="h4" fontWeight="900">{connectionCount}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography sx={{ px: 2, textAlign: "left" }}>アポ数</Typography>
      <Divider sx={{ mt: 1 }}></Divider>
      <Typography sx={{ px: 2 }} variant="h4" fontWeight="900">{appointmentCount}</Typography>
    </Box>
  </Box>
)

export default CallResultSummary