import { onAuthStateChanged } from 'firebase/auth'
import { useEffect } from 'react'
import { auth } from '../google/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getCurrentUser, initUserSlice, resetUserState,  } from '@/store/userSlice'
import { AppDispatch } from '@/store/store'
import { getDocData, getDocRef, getSnapshot, updateDocument } from '../google/firestore'
import { initCallSlice } from '@/store/callSlice'
import { onSnapshot, Unsubscribe } from 'firebase/firestore'
import { initCallStatsSlice } from '@/store/callStatsSlice'
import { User } from '@/models/User'
import { initCompanySlice } from '@/store/companySlice'

let unsubscribes: Array<Unsubscribe> = []
let tenantId: string

export const useAuth = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        return onAuthStateChanged(auth, async (user) => {
            console.debug("onAuthStateChanged: " + (user?.tenantId || ""))
            if (user) {
                tenantId = user.tenantId

                if(location.pathname === `/${tenantId}/signin` || location.pathname === '/')
                    navigate(`/${user.tenantId}/`)
                
                if(!unsubscribes.length) {
                    console.debug("subscribe user")
                    unsubscribes = [onSnapshot(getDocRef(`/users/${user.uid}`), async (snapshot) => {
                        console.debug("refetch current user")
                        dispatch(getCurrentUser())
                    })]

                    const firestoreUser = await getDocData(`/users/${user.uid}`) as User
                    const process = firestoreUser.assignedPid ? await getSnapshot(`/companies/${user.tenantId}/callProcesses/${firestoreUser.assignedPid}`) : null
                    
                    // 以下のいずれかに当てはまればNOT_ASSIGNEDへリセット
                    // - プロセスが割り当てられていない
                    // - 割り当てられたプロセスが存在しない 
                    // - 割り当てられたプロセスのステータスがAborted
                    if(!firestoreUser.assignedPid || !process?.exists() || process?.data().status === "ABORTED")
                        await updateDocument(                 
                            `/users/${user.uid}`,
                            { callState: "NOT_ASSIGNED" }
                        )
                    await dispatch(getCurrentUser())
                }
            } else {
                unsubscribes = []
                dispatch(initCallStatsSlice())
                dispatch(initCallSlice())
                dispatch(initUserSlice())
                dispatch(initCompanySlice())
                dispatch(resetUserState()) // firestoreのユーザー状態を変更するのはログイン中のみ可能なためauth.ts側で変更
                if(tenantId && !location.pathname.endsWith("/signin"))
                    navigate(`/${tenantId}/signin`)
                else if(location.pathname !== "/" && !location.pathname.endsWith("/signin"))
                    navigate("/")
                tenantId = ""
            }
        })
    }, [dispatch, location, navigate, tenantId])
}