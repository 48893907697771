import { useState } from 'react';
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import dialShiftLogo from '@/assets/dialshift_logo.svg';
import { useNavigate } from 'react-router-dom';

const InputTenant = () => {
  const [tenantId, setTenantId] = useState("");
  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ pt: 12 }}>
      <Box pb={3}>
        <img src={dialShiftLogo} style={{ minWidth: 160 }}></img>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mx: 'auto', p: 4, bgcolor: '#ffffff', borderRadius: 1 }} maxWidth={390}>
        <Typography variant="h3" mb={4} fontWeight={900} color="#1C75B5">
          Sign in
        </Typography>
        <TextField
          label="テナントID"
          id='tenantId'
          value={tenantId}
          onChange={(e) => setTenantId(e.target.value)}
          size="small"
          sx={{ mb: 2 }}
        ></TextField>
        <Button
          disabled={!tenantId}
          onClick={() => {navigate(`/${tenantId}/signin`)}}
        >
          ログイン画面に進む
        </Button>
      </Box>
    </Container>
  );
};

export default InputTenant;
