import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"


export const CallScreenDialog = ({ open, onCancel, onConfirm }: {
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <Typography>
          通話を終了しますか？
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button color="primary" onClick={onCancel}>キャンセル</Button>
        <Button color="error" onClick={onConfirm}>通話終了</Button>
      </DialogActions>
    </Dialog>
  )
}