import { Avatar, Box, Container, Grid, IconButton, Slider, Stack, styled, Tooltip, Typography,} from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { BreadcrumbRef, DSBreadcrumbs } from '@/components/DSBreadcrumbs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CallHistoryRecord } from '@/models/CallHistory';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { ContentCopy, Pause, PhoneCallback, PhoneForwarded, PlayArrow, QuestionMark, Download } from '@mui/icons-material';
import formatTimestamp from '@/utils/formatTimestamp';
import { useCallListMetadata } from '@/hooks/useCallList';
import { useScripts } from '@/hooks/useScripts';
import { useTranscripts } from '@/hooks/useTranscript';
import { setLoadingBackdrop, setSnackbar } from '@/store/commonSlice';
import { getScriptLines } from '@/store/callSlice';
import { useDispatch } from 'react-redux';
import { downloadFromStorage } from '@/google/storage';
import { CallHistoryCursorFactory } from '@/features/CallHistory/CallHistoryCursor';
import { useCallResultLabels } from '@/hooks/useCallResultLabels';
import dayjs from 'dayjs';
import { getIdToken } from '@/google/auth';
import { CopyCallConversation } from '@/types/CallHistory';

function formatDuration(value: number) {
  const minute = Math.floor(value / 60);
  const secondLeft = (value - minute * 60).toFixed(0);
  return `${minute}:${value - minute * 60 < 10 ? `0${secondLeft}` : secondLeft}`;
}

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

const HistoryDetail = () => {
  const callResultLabels = useCallResultLabels()
  const location = useLocation()
  const urlParameteres = useParams()
  const { tenantId } = useParams()
  const callHistoryId = urlParameteres.id
  const dispatch = useDispatch<AppDispatch>()
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const [callHistory, setCallHistory] = useState<CallHistoryRecord | null>(null)
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0)
  const [audioDuration, setAudioDuration] = useState(0)
  const callListMetadata = useCallListMetadata()
  const scripts = useScripts()
  const scriptLines = useSelector((state: RootState) => callHistory && state.call.scriptLines[callHistory.scriptId])
  const transcript = useTranscripts(callHistoryId)
  const transcriptArray = useMemo(() => {
    if(!transcript)
      return []
    return transcript.split(/\n/).slice(1).map(str => str.split(":"))
  }, [transcript])

  //console.log("これID", callHistoryId)
  //console.log("これがtranscriptArray",transcriptArray)

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(() => ([
    {
      title: "コール履歴",
      navigateTo: `/${tenantId}/history`
    },
    {
      title: "詳細"
    }
  ]), [])

  const headerItems = [
    {
      label: "コール先電話番号",
      content: (<Box sx={{ whiteSpace: "nowrap" }}>
        {
          callHistory?.callDirection === "INCOMING" ? <PhoneCallback htmlColor="#7FB50B" sx={{ mr: 0.5, fontSize: "1.15rem", mb: -0.5 }} fontSize="small" /> :
          callHistory?.callDirection === "OUTGOING" ? <PhoneForwarded htmlColor="#E15E14" sx={{ mr: 0.5, fontSize: "1.15rem", mb: -0.5 }} fontSize="small" /> :
          <QuestionMark sx={{ mr: 0.5, fontSize: "0.95rem" }} color="inherit"></QuestionMark>
        }
        <Typography display="inline" noWrap>
          { callHistory?.phoneNumber || "─" } {/* ここ変更しました */}
        </Typography>
      </Box>)
    },
    {
      label: "会社名",
      content: callHistory?.companyName || "─"
    },
    {
      label: "コール日時",
      content: callHistory?.createdAt ? <Stack>
        <Box>{ dayjs(callHistory?.createdAt.toDate()).format("YYYY-MM-DD") }</Box>
        <Box>{ dayjs(callHistory?.createdAt.toDate()).format("HH:mm:ss") }</Box>
      </Stack> : "─",
      dense: true  
    },
    {
      label: "コール結果",
      content: callHistory?.result ? callResultLabels[callHistory?.result] : "─"
    },
    {
      label: "コールリスト",
      content: callListMetadata && callListMetadata[callHistory?.callListId]?.callListName || "─",
    },
    {
      label: "スクリプト",
      content: scripts && scripts[callHistory?.scriptId]?.name || "─"
    }
  ]

  // 通話履歴のフェッチ
  useEffect(() => {
    if(!callHistoryId || !companyId) 
      return
    const fetch = async () => {
      const cursor = CallHistoryCursorFactory.create({ companyId, recordsPerPage: 1, filter: { callHistoryId } })
      const history = await cursor.fetchNext()
      setCallHistory(history[callHistoryId])
    }
    fetch()
  }, [callHistoryId, companyId])

  // スクリプトのフェッチ
  useEffect(() => {
    if(!callHistory || !callHistory.scriptId || !companyId)
      return
    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "CallHistoryDetailFetchScript", state: true }))
      await dispatch(getScriptLines({ companyId, scriptId: callHistory.scriptId }))
      dispatch(setLoadingBackdrop({ key: "CallHistoryDetailFetchScript", state: false }))
    }
    fetch()
  }, [callHistory, companyId])

  // 通話音声のフェッチ
  useEffect(() => {
    if(!companyId || !callHistoryId || audioRef.current)
      return
    const fetch = async () => {
      try {
        dispatch(setLoadingBackdrop({ key: "CallHistoryDetailFetchAudio", state: true }))
        const blob = await downloadFromStorage({
          path: `company/${companyId}/log/audio/${callHistoryId}.wav`,
          responseType: "blob",
        }) as Blob
        const blobUrl = URL.createObjectURL(blob)
        const newAudio = new Audio(blobUrl)
        newAudio.addEventListener("loadedmetadata", () => setAudioDuration(newAudio.duration))
        newAudio.addEventListener("timeupdate", () => {
          setAudioCurrentTime(newAudio.currentTime)
        })
        audioRef.current = newAudio
      } catch(e) {
        if(e.code === 404) {
          console.error("call history transcript not found")
        } else {
          console.error(e)
        }
      } finally {
        dispatch(setLoadingBackdrop({ key: "CallHistoryDetailFetchAudio", state: false }))
      }
    }
    fetch()
  }, [companyId, callHistoryId])

  const callerBox = (response: string, key: any) => (
    response &&
    <Box display="flex" gap={1} ml="auto" key={key} pl={10}>
      <Box px={2} py={1} bgcolor="#999" color="#fff" fontSize="0.85rem" borderRadius={1}>
        { scriptLines && scriptLines[response]?.[0] || `${response}` }
      </Box>
      <Avatar sx={{ bgcolor: "#999" }}>
        AI
      </Avatar>
    </Box>
  )

  const calleeBox = (response: string, key: any) => (
    response &&
    <Box display="flex" gap={1} key={key} pr={10}>
      <Avatar sx={{ bgcolor: "#DCDCDC", color: "#000", fontSize: "1rem" }}>
        顧客
      </Avatar>
      <Box px={2} py={1} bgcolor="#DCDCDC" fontSize="0.85rem" borderRadius={1}>
        { response }
      </Box>
      
    </Box>
  )

  const handleClickPlay = () => {
    audioRef.current.play()
  }

  const handleClickPause = () => {
    audioRef.current.pause()
  }

  const handleAudioSliderChange = (event, value) => {
    setAudioCurrentTime(value)
    audioRef.current.currentTime = value
  }

  // ページ遷移時に再生を停止
  useEffect(() => {
    return () => {
      if(audioRef.current)
        audioRef.current.pause()
    }
  }, [location])




const fetchCallConversationCopy = async (companyId: string, callHistoryId: string): Promise<string> => {
  const url = `${import.meta.env.VITE_EXPORT_RESULT_API_URL}/callhistory/callconversation/copy?companyId=${companyId}&callHistoryId=${callHistoryId}`;
  const token = await getIdToken();
  try{
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const data: CopyCallConversation = await response.json();
    return data.copyText;
  } catch (error) {
    console.error('fetchCallConversationCopy error', error);
    throw error;
  }
};

const handleCopyClick = async () => {
  if (!companyId || !callHistoryId) return;
  try {
    const copyText = await fetchCallConversationCopy(companyId, callHistoryId);
    await navigator.clipboard.writeText(copyText);
    dispatch(
      setSnackbar({
        open: true,
        text: "コピーしました",
        severity: "success",
      })
    );
  } catch (error) {
    console.error('コピーに失敗しました', error);
    dispatch(
      setSnackbar({
        open: true,
        text: "コピーに失敗しました",
        severity: "error",
      })
    );
  }
};


const fetchAudioFile = async (companyId: string, callHistoryId: string, callInfo:CallHistoryRecord): Promise<void> => {
  const url = `${import.meta.env.VITE_EXPORT_RESULT_API_URL}/callhistory/audio?companyId=${companyId}&callHistoryId=${callHistoryId}`;
  const token = await getIdToken();
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`HTTP status ${response.status}`);
    }
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    const companyName = callInfo.companyName;
    const callDate = dayjs(callInfo?.createdAt.toDate()).format("YYYY-MM-DD");
    const callResult = callInfo?.result ?callResultLabels[callInfo?.result] : ""
    const fileName = `${companyName}-${callDate}-${callResult}.wav`;
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    dispatch(
      setSnackbar({
        open: true,
        text: "ダウンロードしました",
        severity: "success",
      })
    )
  } catch (error) {
    console.error('fetchAudioFile error', error);
    dispatch(
      setSnackbar({
        open: true,
        text: "ダウンロードに失敗しました",
        severity: "error",
      })
    );
    throw error;
  }
};

  return (
    <Container maxWidth="lg" sx={{ p: 4 }}>
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack mt={4} sx={{ px: 2 }} minHeight={400}>
        <Grid container spacing={2}>
          {
            headerItems.map(item => (
              <Grid item xs={2} key={item.label}>
                <Stack bgcolor="#fff" borderRadius={2} py={item.dense ? 1.5 : 3} px={{ md: 1, lg: 2, xl: 3 }} overflow="hidden">
                  <Typography fontSize="0.8rem" color="#888" noWrap>
                    { item.label }
                  </Typography>
                  <Typography textOverflow="ellipsis" overflow="hidden" noWrap>
                    { item.content }
                  </Typography>
                </Stack>
              </Grid>
            ))
          }
        </Grid>

        <Grid container spacing={3} mt={2} height="100%" minHeight={600}>
          <Grid item xs={3}>
            <Grid container direction="column">
              <Grid item xs={8}>
                <Box bgcolor="#fff" borderRadius={2} overflow="hidden">
                  <Box bgcolor="#ddd" py={0.8} px={2}>
                    <Typography fontSize="0.85rem" textAlign="left">コールメモ</Typography>
                  </Box>
                  <Box p={2} fontSize="0.85rem">
                    {
                      callListMetadata && callHistory && callHistory.callMemo || "(メモなし)"
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4} pt={2}>
                <Stack bgcolor="#fff" height="100%" borderRadius={2} overflow="hidden">
                <Box bgcolor="#ddd" py={0.8} px={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography fontSize="0.85rem" textAlign="left">録音</Typography>
                    <Tooltip title="ダウンロード" placement="top">
                      <IconButton onClick={() => fetchAudioFile(companyId, callHistoryId, callHistory)} disabled={audioDuration === 0} size="small">
                        <Download fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Stack p={2}>
                    <Slider
                      step={1}
                      size="small"
                      sx={{ mb: 2 }}
                      value={audioCurrentTime}
                      onChange={handleAudioSliderChange}
                      max={audioDuration || 0}
                    ></Slider>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: -2,
                      }}
                    >
                      <TinyText>{formatDuration(audioCurrentTime)}</TinyText>
                      <TinyText>-{
                        formatDuration(audioDuration - audioCurrentTime)
                      }</TinyText>
                    </Box>
                    <Box>
                      <IconButton onClick={audioRef.current?.paused ? handleClickPlay : handleClickPause}>
                        {
                          audioRef.current?.paused ? <PlayArrow fontSize="large"></PlayArrow> :
                          <Pause fontSize="large"></Pause>
                          
                        }
                      </IconButton>
                    </Box>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Box bgcolor="#fff" height="100%" borderRadius={2} overflow="hidden">
            <Box bgcolor="#ddd" py={0.8} px={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontSize="0.85rem" textAlign="left">文字起こし</Typography>
                <Tooltip title="コピー" placement="top">
                  <IconButton onClick={handleCopyClick} disabled={transcriptArray.length === 0} size="small">
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ overflowY: "scroll", scrollbarColor: "#ddd #f6f6f6" }}>
                <Stack p={3} gap={1} maxWidth={680} maxHeight={550} mx="auto">
                  {
                    transcriptArray.map((line, k) => line[0] && line[1] &&
                      line[0] === "Caller" ? callerBox(line[1], k) :
                      line[0] === "Callee" ? calleeBox(line[1], k) :
                      ""
                    )
                  }
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  )
}

export default HistoryDetail