import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./userSlice"
import commonReducer from "./commonSlice"
import callSlice from "./callSlice";
import callStatsSlice from "./callStatsSlice";
import companySlice from "./companySlice"

const store = configureStore({
    reducer: {
        user: userReducer,
        call: callSlice,
        callStats: callStatsSlice,
        common: commonReducer,
        company: companySlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store