import { getAiRedialCalls, getManualRedialCalls } from "@/features/Redial/GetRedialCalls"
import { updateAiRedialCalls, updateManualRedialCalls } from "@/store/callSlice"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useCompanyConfig } from "./useCompanyConfig"
import { Timestamp } from "firebase/firestore"
import { callResults } from "@/models/CallHistory"

let fetching = false

export const useRedialCalls = ({ disableLoading = false }: {
  disableLoading?: boolean
} = {}) => {
  const { companyName, callResultsToAiRedial, callResultsToManualRedial, redialIntervalDays, redialMaxAttempts } = useCompanyConfig()
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
  const manualRedialCalls = useSelector((state: RootState) => state.call.manualRedialCalls)
  const aiRedialCalls = useSelector((state: RootState) => state.call.aiRedialCalls)
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const fetch = async () => {
      if(!isSignedIn || fetching || !companyName)
        return
      if(Object.keys(manualRedialCalls).length || Object.keys(aiRedialCalls).length)
        return
      fetching = true

      if(!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: true }))
      
      try {
        // 再架電間隔と再架電最大回数 (初期値は5日間隔で3回)
        const [manual, ai] = await Promise.all([
          getManualRedialCalls({
            companyId,
            callResultsToRedial: callResultsToManualRedial,
            redialMaxAttempts: {
              ...Object.fromEntries(callResults.map(result => [result, 3])),
              ...redialMaxAttempts,
            }
          }),
          getAiRedialCalls({
            companyId,
            callResultsToRedial: callResultsToAiRedial,
            redialIntervalDays: {
              ...Object.fromEntries(callResults.map(result => [result, 5])),
              ...redialIntervalDays,
            },
            redialMaxAttempts: {
              ...Object.fromEntries(callResults.map(result => [result, 3])),
              ...redialMaxAttempts,
            }
          })
        ])
        dispatch(updateManualRedialCalls(manual.filter(history => {
          // 普通のオブジェクトも混じっているため
          if(history.nextCallDate && !history.nextCallDate.toDate)
            history.nextCallDate = new Timestamp(history.nextCallDate.seconds, history.nextCallDate.nanoseconds)
          return history.nextCallDate && (Date.now() > history.nextCallDate.toDate().getTime())
        })))
        dispatch(updateAiRedialCalls(ai))
        
        console.log("Fetched redial calls successfully")
      } catch (error) {
        console.error("Error fetching redial calls:", error)
      } finally {
        dispatch(setLoadingBackdrop({ key: "fetchRedialCalls", state: false }))
      }

      if(!disableLoading)
        dispatch(setLoadingBackdrop({ key: "useCompany", state: false }))
      fetching = false
    }
    fetch()
  }, [dispatch, isSignedIn, companyId, disableLoading, companyName, manualRedialCalls, aiRedialCalls])

  return { manualRedialCalls, aiRedialCalls }
}