import { Box, Divider, Typography } from "@mui/material";

type Props = {
  answerCount: number,
  tossUpCount: number,
  appointmentCount: number
}

const CallResultSummaryLarge = ({ answerCount, tossUpCount, appointmentCount }: Props) => (
  <Box display="flex" justifyContent="space-between">
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <Typography sx={{ px: 2, textAlign: "left", whiteSpace: "nowrap", fontSize: "0.9rem" }}>接続数</Typography>
      <Divider sx={{ mt: 1, mb: 1 }}></Divider>
      <Typography sx={{ px: 1 }} variant="h4" fontWeight="900">{answerCount}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <Typography sx={{ px: 2, textAlign: "left", whiteSpace: "nowrap", fontSize: "0.9rem" }}>トスアップ数</Typography>
      <Divider sx={{ mt: 1, mb: 1, mx: -1 }}></Divider>
      <Typography sx={{ px: 1 }} variant="h4" fontWeight="900">{tossUpCount}</Typography>
    </Box>
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <Typography sx={{ px: 2, textAlign: "left", whiteSpace: "nowrap", fontSize: "0.9rem" }}>アポ数</Typography>
      <Divider sx={{ mt: 1, mb: 1 }}></Divider>
      <Typography sx={{ px: 1 }} variant="h4" fontWeight="900">{appointmentCount}</Typography>
    </Box>
  </Box>
)

export default CallResultSummaryLarge