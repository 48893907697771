import { getRandomDocId } from "@/google/firestore"
import useAxiosWithIdToken from "@/hooks/useAxiosWithIdToken"
import { CallResult } from "@/models/CallHistory"
import { CallListItem } from "@/models/CallList"
import TwilioCallInfo from "@/models/TwilioCall"
import { User } from "@/models/User"
import { Dayjs } from "dayjs"
import { Timestamp } from "firebase/firestore"

const createCallResultBody = async ({
  user,
  fromManualCall,
  callResult,
  nextCallDate,
  callMemo,
  tossUpCallInfo
}: {
  user: User,
  fromManualCall: boolean,
  callResult: CallResult,
  nextCallDate?: Dayjs,
  callMemo?: string,
  tossUpCallInfo?: TwilioCallInfo   // calls/call
}) => {
  // 手動通話の結果登録
  if(fromManualCall) {
    return {
      result: callResult,
      callMemo: callMemo,
      nextCallDate: nextCallDate ? Timestamp.fromDate(nextCallDate.toDate()) : undefined
    }
  } else {    
    // if(!tossUpCallInfo)
    //   throw new Error("tossUpCallInfo is missing")

    const callResultBody = {
      result: callResult,
      callMemo: callMemo,
      nextCallDate: nextCallDate ? Timestamp.fromDate(nextCallDate.toDate()) : undefined
    }
    if(!nextCallDate)
      delete callResultBody.nextCallDate
    return callResultBody
  }
}

export const sendCallResult = async ({
    user,
    fromManualCall,
    callResult,
    nextCallDate,
    callMemo,
    tossUpCallInfo,
    axiosWithId,
}: {
    user: User,
    fromManualCall: boolean,
    callResult: CallResult,
    axiosWithId: ReturnType<typeof useAxiosWithIdToken>,
    nextCallDate?: Dayjs,
    callMemo?: string,
    ongoingManualCallSid?: string,
    tossUpCallInfo?: TwilioCallInfo
}) => {
  const json = JSON.stringify(await createCallResultBody({
    user,
    fromManualCall,
    callResult,
    nextCallDate,
    callMemo,
    tossUpCallInfo
  }))

  if(!user.call)
    throw new Error("user.call is missing")

  const nextCallUrl = user.call.manual ?
    import.meta.env.VITE_NGROK_URL + `/manual-call-event/${user.companyId}/${user.uid}/${user.call.callSid}` :
    import.meta.env.VITE_NGROK_URL + `/nextcall`

  console.log("send call result", json)

  // バックエンドへ nextcall を通知
  return await axiosWithId.post(nextCallUrl, json, {
    headers: {
      "Content-Type": "application/json",
      'ngrok-skip-browser-warning': 'true'
    },
  })
}