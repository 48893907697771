import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Button, Checkbox, TextField, Container, Typography, Box, IconButton, InputAdornment, Collapse, Snackbar } from '@mui/material';
import dialShiftLogo from '@/assets/dialshift_logo.svg';
import { signInWithEmail, signInWithEmailLink, sendSignInLinkToEmail, isSignInWithEmailLink } from '@/google/auth';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';

const SignIn = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { tenantId } = useParams();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usingPassword, setUsingPassword] = useState(false);
  const [isSignInButtonDisabled, setIsSignInButtonDisabled] = useState(false);
  const [helperText, setHelperText] = useState("");

  const isValidEmail = (email: string) => Boolean(email.match(/.+@.+\..+/));

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const signIn = async () => {
    if (usingPassword) {
      const cred = await signInWithEmail(email, password, tenantId);
      setEmail("");
      setPassword("");
      return cred
    } else {
      return sendSignInLinkToEmail(email, tenantId);
    }
  };

  if (isSignInWithEmailLink(tenantId)) {
    const email = window.localStorage.getItem('emailForSignIn');
    signInWithEmailLink(email, tenantId).then(() => {
      window.localStorage.removeItem('emailForSignIn');
    });
  }

  const handleSignInButtonClick = async () => {
    if (!usingPassword) {
      setIsSignInButtonDisabled(true);
      setTimeout(() => {
        setIsSignInButtonDisabled(false);
      }, 5000);
    }
    try {
      await signIn();
      setHelperText("");
    } catch(e) {
      console.error(e);
      setHelperText("メールアドレスまたはパスワードが異なります。");
    }
  };

  useEffect(() => {
    if(!isValidEmail(email))
      setHelperText("メールアドレスの形式で入力してください");
    else
      setHelperText("");
  }, [email, usingPassword]);
  return (
    <Container maxWidth="md" sx={{ pt: 12 }}>
      <Box pb={3}>
        <img src={dialShiftLogo} style={{ minWidth: 160 }}></img>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mx: 'auto', p: 4, bgcolor: '#ffffff', borderRadius: 1 }} maxWidth={390}>
        <Typography variant="h3" mb={4} fontWeight={900} color="#1C75B5">
          Sign in
        </Typography>
        <TextField
          label="テナントID"
          value={tenantId}
          size="small"
          sx={{ mb: 4 }}
          disabled={true}
        ></TextField>
        <TextField
          label="ログインID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="small"
          sx={{ mb: 2 }}
          error={Boolean(helperText)}
          helperText={helperText}
        ></TextField>
        <Box
          sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
          onClick={() => setUsingPassword(!usingPassword)}
        >
          <Checkbox
            checked={usingPassword}
            onChange={() => setUsingPassword(!usingPassword)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography sx={{ cursor: 'default' }}>
            パスワードを使う
          </Typography>
        </Box>
        <Collapse in={usingPassword}>
          <TextField
            label="パスワード"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            size="small"
            sx={{ mb: 4, width: '100%' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                    >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Collapse>
        <Button
          disabled={!email || (!password && usingPassword) || isSignInButtonDisabled}
          onClick={() => {handleSignInButtonClick()}}
        >
          {usingPassword ? "ログイン" : "メールでログイン"}
        </Button>
      </Box>
      <Snackbar
        open={isSignInButtonDisabled}
        autoHideDuration={6000}
        message="ログインリンクをメールで送信しました"
      />
    </Container>
  );
};

export default SignIn;
