import { Box, Container, Divider, Fade, Skeleton, Stack } from "@mui/material"
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import RedialManual from "@/features/Redial/RedialManual"
import RedialAi from "@/features/Redial/RedialAi"
import { useDispatch } from "react-redux"
import { useRedialCalls } from "@/hooks/useRedialCalls"
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";
import { deleteManualRedialCalls } from "@/store/callSlice"
import { updateDocument } from "@/google/firestore"

const Redial = React.memo(() => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const role = useSelector((state: RootState) => state.user.role)
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
  const { tenantId } = useParams()
  const { aiRedialCalls, manualRedialCalls } = useRedialCalls()
  const companyPhoneNumbers = useCompanyPhoneNumbers()
  const breadcrumbRefs: BreadcrumbRef[] = useMemo(() => ([
    { title: "再コール" }
  ]), [])
  const loading = useSelector((state: RootState) => state.common.loadingBackdrop.fetchNurturingCalls)
  const showSkeleton = useMemo(() => (role !== "ADMIN" && role !== "SV") || loading, [role, loading])

  const onUpdateNextCalled = useCallback(async (ids: string[]) => {
    await Promise.all(ids.map(async (id: string) => {
      const call = manualRedialCalls[id]
      if(!call)
        return
      console.log(id)
      updateDocument(`/companies/${companyId}/callHistory/${id}`, {
        ...call, nextCalled: true
      })
    }))
    dispatch(deleteManualRedialCalls(ids))
  }, [manualRedialCalls, companyId])

  // ADMINかSVでないならhomeへ遷移
  useEffect(() => {
    if(!isSignedIn || !role)
      return
    if(role !== "ADMIN" && role !== "SV")
      navigate(`/${tenantId}`)
  }, [isSignedIn, role, tenantId])
  

  return (
    <Container sx={{ py: 4, maxWidth: 900, mx: "auto" }}>
      <Box mb={2}>
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      <Box width={722} mx="auto">
        {/* <Tabs value="manual" sx={{ mb: 2 }}>
          <Tab label="手動コール" value="manual" />
          <Tab label="AIコール" value="ai" />
        </Tabs> */}
        {
          showSkeleton ? <Stack maxWidth={786} mx="auto" gap={4}>
            <Skeleton height={607} variant="rectangular" animation="wave"></Skeleton>
            <Skeleton height={607} variant="rectangular" animation="wave"></Skeleton>
          </Stack> :
          <Stack gap={4}>
            <Fade in={!showSkeleton}>
              <div>
                <RedialManual
                  nurturingCalls={Object.values(manualRedialCalls)}
                  onUpdateNextCalled={onUpdateNextCalled}
                ></RedialManual>     
              </div>
            </Fade>
            <Divider></Divider>
            <Fade in={!showSkeleton}>
              <div>
                <RedialAi
                  nurturingCalls={Object.values(aiRedialCalls)}
                  companyPhoneNumbers={companyPhoneNumbers}
                ></RedialAi>
              </div>
            </Fade>
          </Stack>
        }
      </Box>
    </Container>
  )
})

export default Redial