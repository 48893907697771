import { Box, Button, Checkbox, Divider, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { Option, SimpleSelect } from "@/components/SimpleSelect"
import { useCallListMetadata } from "@/hooks/useCallList"
import { useScripts } from "@/hooks/useScripts"
import { useCallback, useEffect, useState } from "react"
import {CallStatsFilter, CallStatsPeriod, callStatsPeriodLabels, callStatsPeriods, showingCallResultOptions } from "@/google/callStats"
import { RootState } from "@/store/store"
import { useSelector } from "react-redux"
import { HistoryOutlined, PhoneOutlined } from "@mui/icons-material"
import { useCallResultLabels } from "@/hooks/useCallResultLabels"

const weekdayOptions = [
  { value: "SUNDAY", label: "日" },
  { value: "MONDAY", label: "月" },
  { value: "TUESDAY", label: "火" },
  { value: "WEDNESDAY", label: "水" },
  { value: "THURSDAY", label: "木" },
  { value: "FRIDAY", label: "金" },
  { value: "SATURDAY", label: "土" }
] as const

type Props = {
  handleFilterApply: (filter: CallStatsFilter) => void
}

const CallProcessLogFilter = ({ handleFilterApply }: Props) => {
  const callResultLabels = useCallResultLabels()
  const companyId = useSelector((state: RootState) => state.user.companyId)
  const scripts = useScripts()
  const callLists = useCallListMetadata()
  const [filter, setFilter] = useState<CallStatsFilter>({
    companyId,
    period: "TODAY",
    scriptId: "",
    callListId: "",
    daysOfWeek: [],
    showingCallResults: [...showingCallResultOptions]
  })

  const handleWeekdays = (
    event: React.MouseEvent<HTMLElement>,
    daysOfWeek: string[],
  ) => {
    setFilter({
      ...filter,
      daysOfWeek
    })
  };

  const handleCallResultCheckboxChange = useCallback((result: CallStatsFilter["showingCallResults"][number], state: boolean) => {
    setFilter({
      ...filter,
      showingCallResults: state ? filter.showingCallResults.concat(result) : filter.showingCallResults.filter(r => r !== result)
    })
  }, [filter])

  const handleClearFilterClick = useCallback(() => {
    setFilter({
      companyId,
      period: "TODAY",
      scriptId: "",
      callListId: "",
      daysOfWeek: [],
      showingCallResults: [...showingCallResultOptions]
    })
  }, [companyId])

  return (
    <Stack
      borderRadius={2}
      display="flex"
      sx={{
        ".base-Select-popup": {
          zIndex: 100,
          maxHeight: 400
        }
      }}
      gap={2}
      minWidth={240}
      maxWidth={360}
    >
    
      <Stack textAlign="left" gap={0.5}>
        <Box display="flex" gap={1} mb={1}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography>
              検索条件
            </Typography>
          </Stack>
          
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              padding: "6px 10px",
              lineHeight: 1,
              fontSize: "0.80rem",
              boxShadow: "0px 2px 4px rgba(0,0,0, 0.05)",
              ml: "auto"
            }}
            onClick={handleClearFilterClick}
            color="greyNormal"
          >
            クリア
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              padding: "6px 10px",
              lineHeight: 1,
              fontSize: "0.80rem",
              boxShadow: "0px 2px 4px rgba(0,0,0, 0.05)"
            }}
            onClick={() => handleFilterApply(filter)}
          >
            適用
          </Button>
        </Box>
        
      
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ mr: 1 }} fontSize="0.80rem" display="flex" flexDirection="column" justifyContent="center">スクリプト</Typography>
          <SimpleSelect
            value={filter.scriptId}
            onChange={(_, newValue: string) => setFilter({ ...filter, scriptId: newValue })}
            style={{ width: 160 }}
            size="xs"
          >
            <Option value="" size="xs">
              全て
            </Option>
          {
            Object.entries(scripts??{}).map(([id, metadata]) => (
                <Option value={id} size="xs" key={id}>
                  { metadata.name || "名前なし" }
                </Option>
            ))
          }
          </SimpleSelect>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ mr: 1 }} fontSize="0.80rem" display="flex" flexDirection="column" justifyContent="center">リスト</Typography>
          <SimpleSelect
            value={filter.callListId}
            onChange={(_, newValue: string) => setFilter({ ...filter, callListId: newValue })}
            style={{ width: 160 }}
            size="xs"
          >
            <Option value="" size="xs">
              全て
            </Option>
            {
              Object.entries(callLists??{}).map(([id, metadata]) => (
                  <Option value={id} size="xs" key={id}>
                    { metadata.callListName || "名前なし" }
                  </Option>
              ))
            }
          </SimpleSelect>
        </Box>
      </Stack>

      <Divider></Divider>

      <Stack>
        <Stack direction="row" mb={1} gap={0.5} alignItems="center">
          <HistoryOutlined fontSize="small"></HistoryOutlined>
          <Typography>
            検索期間
          </Typography>
        </Stack>

        <SimpleSelect
          value={filter.period}
          onChange={(_, newValue: CallStatsPeriod) => setFilter({ ...filter, period: newValue })} size="xs">
          {
            callStatsPeriods.map(period => (
              <Option value={period} key={period} size="xs">
                { callStatsPeriodLabels[period] }
              </Option>
            ))
          }
        </SimpleSelect>

        <Box mt={2}>
          <ToggleButtonGroup
            size="small"
            sx={{ bgcolor: "#fff" }}
            value={filter.period === "THIS_YEAR" ? [] : filter.daysOfWeek}
            onChange={handleWeekdays}
            disabled={filter.period === "THIS_YEAR"}
          >
            {
              weekdayOptions.map(day => (
                <ToggleButton
                  key={day.value} value={day.value}
                  sx={{
                    width: 34,
                    height: 34,
                    // "&.Mui-selected": {
                    //   color: "#fff",
                    //   bgcolor: "#1976d2"
                    // }
                  }}
                >
                  {day.label}
                </ToggleButton>
              ))
            }
          </ToggleButtonGroup>
        </Box>
      </Stack>

      <Divider></Divider>

      <Stack>
        <Stack direction="row" mb={1} gap={0.5} alignItems="center">
          <PhoneOutlined fontSize="small"></PhoneOutlined>
          <Typography>
            コール結果
          </Typography>
        </Stack>

        <Stack gap={1} direction="row">
          <Stack gap={1}>
            {
              showingCallResultOptions.slice(0,10).map(result => (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    checked={filter.showingCallResults?.includes(result)}
                    onChange={(_, checked) => handleCallResultCheckboxChange(result, checked)}
                  ></Checkbox>
                  <Typography fontSize="0.85rem">
                    { callResultLabels[result] }
                  </Typography>
                </Stack>
              ))
            }
          </Stack>
          <Stack gap={1}>
            {
              showingCallResultOptions.slice(10).map(result => (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Checkbox
                    size="small"
                    sx={{ p: 0 }}
                    checked={filter.showingCallResults?.includes(result)}
                    onChange={(_, checked) => handleCallResultCheckboxChange(result, checked)}
                  ></Checkbox>
                  <Typography fontSize="0.85rem">
                    { callResultLabels[result] }
                  </Typography>
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CallProcessLogFilter
