import { Box,  Container, Divider, Fade, Pagination, Stack } from "@mui/material"
import CallListFilter from "../../features/CallHistory/CallHistoryFilter"
import { CallHistoryEntry } from "@/features/CallHistory/CallHistoryEntry"
import { CallHistoryHeader } from "@/features/CallHistory/CallHistoryHeader"
import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { useCallback, useState } from "react"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { useDispatch } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { useSelector } from "react-redux"
import { CallHistoryCursor, CallHistoryCursorFactory, CallHistoryFilter } from "@/features/CallHistory/CallHistoryCursor"
import { initCallHistory, setCallHistoryCurrentPage, setCallHistoryPageIsFetched, setFilteredCallHistoryNum, updateLocalCallHistory } from "@/store/callSlice"

const HISTORY_PER_PAGE = 10
let cursor: CallHistoryCursor | null = null

const getPageNum = (length: number) => Math.floor((length-1)/HISTORY_PER_PAGE+1)

const History = () => {
  const breadcrumbRefs: BreadcrumbRef[] = [
    { title: "コール履歴" }
  ]
  const filteredCallHistoryNum = useSelector((state: RootState) => state.call.filteredCallHistoryNum)
  const history = useSelector((state: RootState) => state.call.callHistory)
  const page = useSelector((state: RootState) => state.call.callHistoryCurrentPage)
  const pageIsFetched = useSelector((state: RootState) => state.call.callHistoryPageIsFetched)
  const user = useSelector((state: RootState) => state.user)
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
  const dispatch = useDispatch<AppDispatch>()
  
  const handleFilterSubmit = useCallback(async (filter: CallHistoryFilter) => {
    if(!user.isSignedIn || !user.companyId)
      return
    if(cursor && Object.keys(filter).map(key => filter[key] === cursor.filter[key]).reduce((prev, curr) => prev && curr, true))
      return

    dispatch(setLoadingBackdrop({ key: "HistoryHandleFilterSubmit", state: true }))

    // コール履歴の初期化
    dispatch(initCallHistory())

    cursor = CallHistoryCursorFactory.create({ companyId: user.companyId, filter, recordsPerPage: HISTORY_PER_PAGE })

    // フィルタ後のレコードの数取得
    const total = await cursor.totalRecordsNum()
    dispatch(setFilteredCallHistoryNum(total))

    console.log("call history total: " + total)

    // 最初の1ページフェッチ
    const fetched = await cursor.fetchNext()
    dispatch(updateLocalCallHistory(fetched))
    const newPageIsFetched = Array.from({ length: getPageNum(total) }, () => false)
    newPageIsFetched[0] = true

    dispatch(setCallHistoryPageIsFetched(newPageIsFetched))
    dispatch(setCallHistoryCurrentPage(1))

    dispatch(setLoadingBackdrop({ key: "HistoryHandleFilterSubmit", state: false }))
  }, [dispatch, isSignedIn, user])

  const handleChangePage = useCallback((e, newPage: number) => {
    if(newPage <= page) {
      dispatch(setCallHistoryCurrentPage(newPage))
      return
    }

    const fetchCount = pageIsFetched.slice(page-1, newPage).filter(f => !f).length
    console.log(pageIsFetched)
    console.log(fetchCount)
    if(fetchCount === 0) {
      dispatch(setCallHistoryCurrentPage(newPage))
      return
    }

    if(!cursor)
      throw new Error("fetcher is not initialized")
    
    const fetch = async () => {
      dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: true }))

      const results = await cursor.fetchNext(fetchCount)
      dispatch(updateLocalCallHistory(results))
      dispatch(setCallHistoryCurrentPage(newPage))

      const newPageIsFetched = [...pageIsFetched].fill(true, page-1, newPage)
      dispatch(setCallHistoryPageIsFetched(newPageIsFetched))
      dispatch(setLoadingBackdrop({ key: "HistoryPageChange", state: false }))
    }

    fetch()
  }, [cursor, dispatch, history, page, pageIsFetched])

  return (
    <Container sx={{ py: 4 }} maxWidth="lg">
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <CallListFilter onSubmit={handleFilterSubmit}></CallListFilter>

      <Divider sx={{ mt: 4 }}></Divider>

      <Box mt={4} mb={1}>
        <CallHistoryHeader></CallHistoryHeader>
      </Box>
      <Stack gap={1.2}>
        {
          Object.values(history).map((record, i) => (
            i >= (page-1)*HISTORY_PER_PAGE && i < page*HISTORY_PER_PAGE &&
            (
              <Fade
                in={i >= (page-1)*HISTORY_PER_PAGE && i < page*HISTORY_PER_PAGE}
                key={i}
                timeout={200}
              >
                <Box>
                  <CallHistoryEntry record={record} key={i}></CallHistoryEntry>
                </Box>
              </Fade>
            )
          ))
        }
      </Stack>
      <Pagination
        count={getPageNum(filteredCallHistoryNum)}
        sx={{ pt: 2, pb: 6, "& .MuiPagination-ul": { justifyContent: "center" } }}
        size="large"
        page={page}
        onChange={handleChangePage}
      ></Pagination>
    </Container>
  )
}

export default History