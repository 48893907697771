// ページネーション用のクラス

import { getCollectionRef } from "@/google/firestore"
import { CallListItem } from "@/models/CallList"
import { getCountFromServer, getDocs, limit, orderBy, query, QueryDocumentSnapshot, startAfter, where } from "firebase/firestore"
import memoize from 'lodash/memoize'

class CallListItemCursor {
    private readonly companyId: string
    private readonly callListId: string
    private readonly itemsPerPage: number
    private readonly callListItemIndex: number | null
    private currentPage: number = 0     // zero-based
    private pageIsFetched?: boolean[]
    private lastDoc?: QueryDocumentSnapshot
    private sort: {
        field: keyof CallListItem,
        order: "asc" | "desc"
    } | null

    constructor({ companyId, callListId, itemsPerPage, callListItemIndex, sort }: {
        companyId: string,
        callListId: string,
        itemsPerPage: number,
        callListItemIndex?: number,
        sort?: {
            field: keyof CallListItem,
            order: "asc" | "desc"
        }
    }) {
        this.companyId = companyId
        this.callListId = callListId
        this.itemsPerPage = itemsPerPage
        this.currentPage = 0
        this.callListItemIndex = callListItemIndex || null
        this.sort = sort || null
    }

    totalNum = memoize(async (): Promise<number> => {
        const q = query(getCollectionRef(`/companies/${this.companyId}/callListItems_v2`), where("callListId", "==", this.callListId))
        const total = (await getCountFromServer(q)).data().count

        return total
    })

    getCurrentPage = () => this.currentPage

    fetchNext = async (newPage: number = 0): Promise<CallListItem[]> => {
        // 特定のitemだけ取得
        if(this.callListItemIndex !== null) {
            const snapshot = await getDocs(query(
                getCollectionRef(`/companies/${this.companyId}/callListItems_v2`),
                where("callListId", "==", this.callListId),
                where("index", "==", this.callListItemIndex)
            ))
            return [snapshot.docs[0].data() as CallListItem]
        }

        if(!this.pageIsFetched)
            this.pageIsFetched = Array.from({ length: Math.floor((await this.totalNum() - 1) / this.itemsPerPage) + 1 }, () => false)

        const pagesToFetch = this.pageIsFetched.slice(0, newPage+1).filter(f => !f).length

        if(pagesToFetch === 0)
            return []

        console.log("pages to fetch: " + pagesToFetch)
    
        const q = this.createNextQuery()
        const querySnapshot = await getDocs(q)

        const items: CallListItem[] = []
        querySnapshot.forEach(snapshot => items.push({
            ...snapshot.data(),
            id: snapshot.id
        } as CallListItem))

        this.pageIsFetched = [...this.pageIsFetched].fill(true, 0, newPage+1)
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1]    // 次ページの開始地点
        this.currentPage = newPage

        return items
    }

    private createNextQuery() {
        const filters = [
            where("callListId", "==", this.callListId),
            this.sort ? orderBy(this.sort.field, this.sort.order) : orderBy("index", "asc"),
            this.lastDoc ? startAfter(this.lastDoc) : null,
            this.itemsPerPage ? limit(this.itemsPerPage) : null,
        ]

        return query(getCollectionRef(`/companies/${this.companyId}/callListItems_v2`), ...filters.filter(f => f !== null))
    }
}

export default CallListItemCursor