import fakeImage from '../assets/config_image.png'

const Config = () => {
  return (
    <div style={{ width: "100%" }}>
      <img src={fakeImage} style={{ width: "100%", maxWidth: 1200, margin: "0 auto" }}></img>
    </div>
  )
}

export default Config