import { useCompanyUsers } from "@/hooks/useCompanyUsers";
import { RootState } from "@/store/store";
import { AddIcCallOutlined, CallOutlined, ContactPhoneOutlined, DialpadOutlined, HistoryOutlined, HomeOutlined, LogoutOutlined, ReceiptLongOutlined, SettingsOutlined } from "@mui/icons-material";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import dialShiftLogo from "@/assets/dialshift_logo.svg";

const drawerItems = [
  { text: "ホーム", to: "/", icon: HomeOutlined },
  { text: "AIコール", to: "/aicall", icon: CallOutlined, availRoles: ["ADMIN", "SV"] },
  { text: "再コール", to: "/redial", icon: AddIcCallOutlined, availRoles: ["ADMIN", "SV"] },
  { text: "スクリプト設定", to: "/script", icon: ReceiptLongOutlined },
  { text: "リスト管理", to: "/calllist", icon: ContactPhoneOutlined },
  { text: "コール履歴", to: "/history", icon: HistoryOutlined }
]

export const DSDrawerContent = ({ onSignOutClick }: { onSignOutClick: () => void }) => {
  const role = useSelector((state: RootState) => state.user.role)
  const uid = useSelector((state: RootState) => state.user.uid)
  const users = useCompanyUsers()
  const { tenantId } = useParams()
  const canBeLoggedOut = useMemo(() => uid && users[uid] && users[uid].callState === "NOT_ASSIGNED", [uid, users])
  const canMoveToManualCall = useMemo(() => uid && users[uid] && users[uid].callState === "NOT_ASSIGNED", [uid, users])

  return (
    <Stack height="100%" pb={4}>
      <Toolbar />
      <Divider />
      <List>
        {drawerItems
        .filter(item => !item.availRoles || (item.availRoles && item.availRoles.includes(role)))
        .map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton component={Link} to={`/${tenantId}${item.to}`}>
              <ListItemIcon sx={{ color: '#ffffff' }}>
                <item.icon/>
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem></ListItem>
        <ListItem></ListItem>
        {
          canMoveToManualCall && <ListItem disablePadding>
            <ListItemButton component={Link} to={`/${tenantId}/manualcall`}>
              <ListItemIcon sx={{ color: '#ffffff' }}>
                <DialpadOutlined></DialpadOutlined>
              </ListItemIcon>
              <ListItemText primary="手動コール" />
            </ListItemButton>
          </ListItem>
        }
        {
          canBeLoggedOut &&
          <ListItem disablePadding>
            <ListItemButton onClick={onSignOutClick}>
              <ListItemIcon sx={{ color: '#ffffff' }}>
                <LogoutOutlined></LogoutOutlined>
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        }
      </List>
    </Stack>
  );
}