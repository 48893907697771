function formatJapanesePhoneNumber(phoneNumber: string): string | false {
  // 数字以外の文字を除去
  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  if(cleanedNumber.length < 5)
    return phoneNumber

  // 各種パターンに対するフォーマット関数
  const formatters: [RegExp, (match: string[]) => string][] = [
    // 0120 (フリーダイヤル)
    [/^(0120)(\d{0,3})(\d{0,3})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 0800 (フリーダイヤル)
    [/^(0800)(\d{0,3})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 0570 (ナビダイヤル)
    [/^(0570)(\d{0,3})(\d{0,3})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],

    // 0120 (フリーダイヤル、4桁-4桁)
    [/^(0120)(\d{0,4})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],

    // 090, 080, 070 (携帯電話)
    [/^(0[7-9]0)(\d{0,4})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],

    // 020 (phs)
    [/^(020)(\d{0,4})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 050 (IP電話)
    [/^(050)(\d{0,4})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 03, 06など (固定電話、2桁市外局番)
    [/^(0\d)(\d{0,4})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 011, 099など (固定電話、3桁市外局番)
    [/^(0\d{2})(\d{0,3})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 0123, 0998など (固定電話、4桁市外局番)
    [/^(0\d{3})(\d{0,2})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    // 01234, 09876など (固定電話、5桁市外局番)
    [/^(0\d{4})(\d{0,1})(\d{0,4})$/, m => `${m[1]}${m[2] ? '-' + m[2] : ''}${m[3] ? '-' + m[3] : ''}`],
    
    
  ];

  // パターンにマッチするか確認し、フォーマットを適用
  for (const [pattern, formatter] of formatters) {
    const match = cleanedNumber.match(pattern);
    if (match) {
      return formatter(match);
    }
  }

  // どのパターンにもマッチしない場合はfalseを返す
  return false;
}

export default formatJapanesePhoneNumber