import { getCollectionRef, getSnapshot, getSnapshots } from "@/google/firestore"
import { User } from "@/models/User"
import { setLoadingBackdrop } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { updateLocalCompanyUsers } from "@/store/userSlice"
import { onSnapshot, query, where } from "firebase/firestore"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Unsubscribe } from "redux"

let unsubscribes: Array<Unsubscribe> = []
let fetchCount = 0

export const useCompanyUsers = (uids: string[] = []) => {
    const user = useSelector((state: RootState) => state.user)
    const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const fetch = async () => {
            if(!user.isSignedIn)
                return
            if(uids.length && uids.reduce((prev, curr) => prev && Boolean(user.companyUsers[curr]), true))
                return
            if(unsubscribes.length)
                return

            setLoadingBackdrop({ key: "useUsers", state: true })

            // ユーザー状態の監視 & store への反映
            const q = query(getCollectionRef("/users"), where("companyId", "==", user.companyId))
            console.log("subscribe users")
            unsubscribes = [onSnapshot(q, async (snapshot) => {
                fetchCount += 1
                if(fetchCount < 1)     // 無駄なrefetchを防ぐため最初の1回はfetchしない
                    return
                console.log("users refetch")
                snapshot.docChanges().forEach(change => {
                    if(change.type === "added" || change.type === "modified") {
                        const updatedUser = change.doc.data() as User
                        dispatch(setLoadingBackdrop({ key: "useUsersAddedOrModified", state: true }))
                        dispatch(updateLocalCompanyUsers({ [updatedUser.uid]: updatedUser }))
                        dispatch(setLoadingBackdrop({ key: "useUsersAddedOrModified", state: false }))
                    }
                })
            })]
            
            let fetchedUsers: { [uid: string]: User } = {}
            if(uids.length) {
                const filteredUids = Array.from(new Set(uids.filter((uid) => !user.companyUsers[uid])))    // 未取得のユーザー情報だけ取得
                if(!filteredUids.length)
                    return {}
                const results = filteredUids.map(uid => getSnapshot(`users/${uid}`))
                const data = (await Promise.all(results))
                    .map(snapshot => snapshot.data())
                console.debug(`getUsers: fetched ${data.length} users`)
                fetchedUsers = Object.fromEntries(data.map(user => [user.uid, user])) 
            } else {
                const q = query(getCollectionRef("users"), where("companyId", "==", user.companyId))
                const results = (await getSnapshots(q)).map(snapshot => snapshot.data() as User)
                console.debug(`getUsers: fetched ${results.length} users`)
                fetchedUsers = Object.fromEntries(results.map(user => [user.uid, user]))
            }
            dispatch(updateLocalCompanyUsers(fetchedUsers))
            dispatch(setLoadingBackdrop({ key: "useUsers", state: false }))
        }
        fetch()
    }, [dispatch, isSignedIn, uids])

    return user.companyUsers
}