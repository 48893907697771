import { callResultColors } from "@/models/CallHistory"
import { useMediaQuery, useTheme } from "@mui/material"
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart"
import { useMemo } from "react"
import { CallStatsFilter } from "@/google/callStats"

type Props = {
  data: Array<{
    id: number | string, value: number, label: string
  }>,
  showingCallResults: CallStatsFilter["showingCallResults"]
}

const CallStatsPieChart = ({ data, showingCallResults }: Props) => {
  const theme = useTheme()
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"))
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"))
  const width = useMemo(() =>
    isXlUp ? 340 :
    isLgUp ? 300 :
    260
  , [isXlUp, isLgUp])
  // Totalとデータの合計が一致していない場合があるので再計算
  // 割合のため値の合計にしています
  const total = useMemo(() => 
    data
      .filter(item => item.id !== 'TOTAL')
      .reduce((acc, curr) => acc + curr.value, 0), 
    [data]
  );
  const showingData = useMemo(() => {
    return showingCallResults ?
      data?.filter(data => showingCallResults.includes(data.id as any)) :
      data
  }, [data, showingCallResults])
  
  return (
    <PieChart
      colors={showingData ? showingData.map(data => callResultColors[data.id]) : ["#cccccc"]}
      series={[{
        data: showingData,
        arcLabel: (item) => `${((item.value / total) * 100).toFixed(0)}%`,
        arcLabelMinAngle: 30,
      }]}
      width={260}
      height={260}
      margin={{left: 0, right: 0}}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontWeight: 'bold',
        },
      }}
      slots={{
        legend: () => <></>
      }}
    />
  )
}

export default CallStatsPieChart