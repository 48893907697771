import { Company } from "@/models/Company";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CompanyState = {
    config: Company
    phoneNumbers: string[]
}

const initialState: CompanyState = {
    config: {
        companyName: "",
        redialMaxAttempts: {},
        redialIntervalDays: {},
        callResultsToAiRedial: [
            "UNREACHABLE"
        ],
        callResultsToManualRedial: [
            "AI_NURTURING",
            "SIMULTANEOUS_TOSSUP",
            "TOSSUP_NURTURING_A",
            "TOSSUP_NURTURING_B",
            "TOSSUP_NURTURING_C",
            "TOSSUP_ABSENT"
        ]
    },
    phoneNumbers: [],
}

const companySlice = createSlice({
    name: "callStats",
    initialState,
    reducers: {
        initCompanySlice: (state, action: PayloadAction) => {
            Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
                state[key] = value
            })
            console.debug("company slice initialized")
        },
        setCompanyConfig: (state, action: PayloadAction<Company>) => {
            state.config = {
                ...state.config,
                ...action.payload
            }
        },
        setCompanyPhoneNumbers: (state, action: PayloadAction<string[]>) => {
            state.phoneNumbers = [...action.payload]
        }
    }
})

export const {
    initCompanySlice,
    setCompanyConfig,
    setCompanyPhoneNumbers,
} = companySlice.actions
export default companySlice.reducer