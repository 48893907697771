import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Call from "./pages/AICall";
import CallList from "./pages/CallList";
import Config from "./pages/Config";
import History from "./pages/History";
import InputTenant from "./pages/inputTenant";
import NotFound from "./pages/NotFound";
import Redial from "./pages/Redial";
import Script from "./pages/Script";
import SignIn from "./pages/signin";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import Layout from "./layouts/default";
import AssignedUsersPopup from "./pages/AssignedUsersPopup";
import CallListAdd from "./pages/CallList/CallListAdd";
import CallListDetail from "./pages/CallList/CallListDetail";
import CallScreen from "./pages/CallScreen";
import HistoryDetail from "./pages/History/HistoryDetail";
import Home from "./pages/Home";
import ManualCall from "./pages/ManualCall";
import { ScriptDetail } from "./pages/Script/ScriptDetail";
import TossUpNotification from "./pages/TossUpNotification";
import store from "./store/store";

declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    greyNormal?: PaletteColorOptions;
    greyDarken?: PaletteColorOptions;
    green?: PaletteColorOptions;
    white?: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    greyNormal: true;
    greyDarken: true;
    green: true;
    white: true;
  }
}

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/teleapo-ai\.jp/,
    /^https:\/\/dev\.teleapo-ai\.jp/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  const apptheme = createTheme({
    typography: {
      fontFamily: [
        "Roboto",
        '"Noto Sans JP"',
        '"Helvetica"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
    palette: {
      greyNormal: {
        main: "#9E9E9E",
        light: "#BDBDBD",
        dark: "#757575",
        contrastText: "#fff",
      },
      greyDarken: {
        main: "#737373",
        light: "#8C8C8C",
        dark: "#595959",
      },
      green: {
        main: "#60B342",
        light: "#7BC461",
        dark: "#4F9236",
        contrastText: "#fff",
      },
      white: {
        main: "#f3f3f3",
        light: "#fff",
        dark: "#ccc",
        contrastText: "#000000de",
      },
    },
  });

  console.log("App rendered");

  // 読み込み中は fallback コンポーネントを表示 (loading)
  // const Home = lazy(() => import("@/pages/Home"))

  return (
    <ThemeProvider theme={apptheme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Provider store={store}>
          <BrowserRouter>
            <SentryRoutes>
              <Route element={<Layout hideDrawer waitUserFetch={false} />}>
                <Route path={`/`} element={<InputTenant />} />
              </Route>
              {/* <Route element={<Layout hideDrawer waitUserFetch={false}/>}>
                <Route path={`signin`} element={<SignIn />} />
              </Route> */}
              <Route path={`/:tenantId`}>
                <Route element={<Layout />}>
                  <Route index element={<Home />} />
                  <Route path={`aicall`} element={<Call />} />
                  <Route path={`redial`} element={<Redial />} />
                  <Route path={`script`} element={<Script />} />
                  <Route path={`script/:id`} element={<ScriptDetail />} />
                  <Route path={`calllist`} element={<CallList />} />
                  <Route path={`calllist/add`} element={<CallListAdd />} />
                  <Route path={`calllist/:id`} element={<CallListDetail />} />
                  <Route path={`history`} element={<History />} />
                  <Route path={`history/:id`} element={<HistoryDetail />} />
                  <Route path={`config`} element={<Config />} />
                  {/* <Route path={`/demo`} element={<Demo />} /> */}
                </Route>
                <Route element={<Layout hideDrawer waitUserFetch={false} />}>
                  <Route path={`signin`} element={<SignIn />} />
                </Route>
                <Route element={<Layout hideDrawer hideAppBar />}>
                  <Route path={`callscreen`} element={<CallScreen />} />
                </Route>
                <Route element={<Layout />}>
                  <Route path={`manualcall`} element={<ManualCall />} />
                </Route>
                <Route
                  path={`assigned-users`}
                  element={<AssignedUsersPopup />}
                />
                <Route
                  path={`tossup-notification`}
                  element={<TossUpNotification />}
                />
                <Route path={`*`} element={<NotFound />} />
              </Route>
            </SentryRoutes>
          </BrowserRouter>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
