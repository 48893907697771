import { setLoadingBackdrop } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getTranscript } from "../store/callSlice"

export const useTranscripts = (callHistoryId: string) => {
    const user = useSelector((state: RootState) => state.user)
    const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
    const transcripts = useSelector((state: RootState) => state.call.transcripts)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        const fetch = async () => {
            if(!user.isSignedIn || !callHistoryId)
                return
            if(transcripts[callHistoryId])
                return
            dispatch(setLoadingBackdrop({ key: "useTranscripts", state: true }))
            await dispatch(getTranscript({ companyId: user.companyId, callHistoryId }))
            dispatch(setLoadingBackdrop({ key: "useTranscripts", state: false }))
        }
        fetch()
    }, [dispatch, isSignedIn, callHistoryId, transcripts])

    return transcripts[callHistoryId]
}