import { Box, Drawer } from "@mui/material";
import { DSDrawerContent } from "./DrawerContent";

const drawerWidth = 240

type Props = {
  onSignOutClick: () => void,
  onClose: () => void,
  onTransitionEnd: () => void,
  mobileOpen: boolean,
}

export const DSDrawer = ({ onSignOutClick, onClose, onTransitionEnd, mobileOpen }: Props) => {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 200 }}
      aria-label="mailbox folders"
    >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={onTransitionEnd}
          onClose={onClose}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              bgcolor: '#1A1A1A',
              color: '#ffffff'
            }
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          <DSDrawerContent onSignOutClick={onSignOutClick}></DSDrawerContent>
        </Drawer>
        <Drawer
          variant="permanent"
          PaperProps={{
            sx: {
              bgcolor: '#1A1A1A',
              color: '#ffffff'
            }
          }}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          <DSDrawerContent onSignOutClick={onSignOutClick}></DSDrawerContent>
        </Drawer>
    </Box>
  )
}