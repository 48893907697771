import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { Box, Container, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import softStyleProps from "@/styles/softStyleProps"
import { ScriptCard } from "@/features/Script/ScriptCard"
import { useScripts } from "@/hooks/useScripts"
import type { Script } from "@/models/Script"

const Script = () => {
  const scripts = useScripts()
  const breadcrumbRefs: BreadcrumbRef[] = [
    { title: "スクリプト設定" }
  ]
  // 有効なスクリプトのみ表示 isDisabledがtrueの場合は非表示
  const  activeScripts = (scripts: { [docId: string]: Script }): { [docId: string]: Script } => {
    return Object.entries(scripts)
      .filter(([_, script]) => script.isDisabled !== true || script.isDisabled === undefined)
      .reduce((acc, [docId, script]) => {
        acc[docId] = script;
        return acc;
      }, {} as { [docId: string]: Script });
  }

  return (
    <Container sx={{ py: 4 }} maxWidth="lg">
      <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      <Stack mt={2} gap={2}>
        {
          Object.entries(activeScripts(scripts)).map(([id, script]) => (
            !script.isDisabled ? (
              <ScriptCard key={id} script={script} scriptId={id}></ScriptCard>
            ) : null
          ))
        }
      </Stack>
    </Container>
  )
}

export default Script