import { getIdToken } from "@/google/auth"
import axios, { AxiosRequestConfig } from "axios"

const useAxiosWithIdToken = () => {
    const gettingIdToken = getIdToken()

    return {
        get: async (url: string, config: AxiosRequestConfig) => {
            const configWithToken = {
                ...config,
                headers: {
                    ...config.headers,
                    'Authorization': `Bearer ${await gettingIdToken}`
                }
            }
            const res = await axios.get(url, configWithToken)
            // if(res.status === 401) {
            //     await dispatch(refreshIdToken())
            //     return axios.get(url, configWithToken)
            // }
            return res
        },
        post: async (url: string, data: string | object, config: AxiosRequestConfig = {}) => {
            const configWithToken = {
                ...config,
                headers: {
                    ...config.headers,
                    'Authorization': `Bearer ${await gettingIdToken}`
                }
            }
            const res = await axios.post(url, data, configWithToken)
            // if(res.status === 401) {
            //     await dispatch(refreshIdToken())
            //     return axios.post(url, data, configWithToken)
            // }
            return res
        }
    }
}

export default useAxiosWithIdToken